import React, { useEffect } from 'react';

import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid } from 'sunwise-ui';

import {
    ReactHookFormDragnDropFile,
    ReactHookFormInput,
} from 'common/components/form/rhf';
import ShowErrors from 'common/components/ShowErrors';
import { FILE_ALLOWED_EXTENSIONS, MAX_SMALL_FILE_SIZE } from 'common/constants';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const DataForm = ({
    canModify,
    errors,
    handleClickSave,
    initialValues,
    isFetchingCompanyData,
    isSavingCompany,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const formValues = watch();

    const isDisabledButton =
        isEqual(initialValues, formValues) || isSavingCompany;

    const isDisabled =
        !canModify || isFetchingCompanyData || isSavingCompany ? true : false;

    const { image = '', name = '' } = formValues;

    return (
        <Box component="form">
            <ReactHookFormDragnDropFile
                accept={FILE_ALLOWED_EXTENSIONS.IMAGE}
                avatarData={{ image, name }}
                control={control}
                disabled={isDisabled}
                formats="jpg, jpeg, png"
                id="image"
                maxSize={MAX_SMALL_FILE_SIZE}
                name="image"
                setValue={setValue}
                variant="profile"
            />
            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                label={t('Tradename')}
                name="name"
            />

            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                label={t('Description')}
                name="description"
            />

            <Grid container mt={1}>
                <Grid item sx={{ textAlign: 'right' }} xs={18}>
                    <Button
                        disabled={isDisabledButton}
                        onClick={handleSubmit(handleClickSave)}
                        sx={{ width: { md: 'auto', xs: '100%' } }}
                        variant="outlined"
                    >
                        {isSavingCompany
                            ? t('Saving').concat('...')
                            : t('Save')}
                    </Button>
                </Grid>
            </Grid>
            <ShowErrors errors={errors} />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getSaveCompanyErrors,
    initialValues: selectors.getInitialValues,
    isSavingCompany: selectors.getIsSavingCompany,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickSave: (values) => dispatch(actions.save(values)),
});

DataForm.propTypes = {
    canModify: PropTypes.bool,
    errors: PropTypes.array,
    handleClickSave: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingCompanyData: PropTypes.bool,
    isSavingCompany: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataForm);
