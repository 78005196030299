import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getInitialValuesTerms = createSelector(
    getModel,
    (model) => model.initialValuesTerms
);

export const getFetchCompanyModel = createSelector(
    getModel,
    (model) => model.fetchCompany
);

export const getIsFetchingCompanyData = createSelector(
    getFetchCompanyModel,
    (fetch) => fetch.isFetching
);

export const getFetchCompanyData = createSelector(
    getFetchCompanyModel,
    (fetch) => fetch.data || []
);

export const getChatsConfig = createSelector(
    getFetchCompanyData,
    ({ chat_alliance, chat_documents }) => ({
        chat_settings: {
            chat_alliance,
            chat_documents,
        },
    })
);

export const getFinancierId = createSelector(
    getFetchCompanyData,
    (data) => data?.id
);

export const getFetchCompanyDataErrors = createSelector(
    getFetchCompanyModel,
    (fetch) => fetch.errors
);

export const getFetchTerms = createSelector(
    getModel,
    (model) => model.fetchTermsAndConditions
);

export const getIsFetchingTerms = createSelector(
    getFetchTerms,
    (model) => model.isFetching
);

export const getFetchTermsData = createSelector(
    getFetchTerms,
    (model) => model.data || []
);

export const getFetchTermsErrors = createSelector(
    getFetchTerms,
    (model) => model.errors
);

export const getSaveCompanyModel = createSelector(
    getModel,
    (model) => model.saveCompany
);

export const getIsSavingCompany = createSelector(
    getSaveCompanyModel,
    (save) => save.isSaving
);

export const getSaveCompanyErrors = createSelector(
    getSaveCompanyModel,
    (save) => save.errors
);

export const getSaveTerms = createSelector(
    getModel,
    (model) => model.saveTerms
);

export const getIsSavingTerms = createSelector(
    getSaveTerms,
    (save) => save.isSaving
);

export const getSaveTermsErrors = createSelector(
    getSaveTerms,
    (save) => save.errors
);
