import React, { useEffect } from 'react';

import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid } from 'sunwise-ui';

import { ReactHookFormFroala } from 'common/components/form/rhf';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validateTerms';

const TermsForm = ({
    errors,
    initialValues,
    isFetchingTerms,
    isSaving,
    saveTerms,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const isDisabledButton =
        isEqual(initialValues, watch()) || isFetchingTerms || isSaving;

    return (
        <form>
            <ReactHookFormFroala control={control} name="content" />

            <ShowErrors errors={errors} />

            <Grid container mt={1}>
                <Grid item textAlign="right" xs>
                    <Button
                        disabled={isDisabledButton}
                        onClick={handleSubmit(saveTerms)}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                        }}
                        type="button"
                        variant="outlined"
                    >
                        {isSaving ? t('Saving').concat('...') : t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

TermsForm.propTypes = {
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isFetchingTerms: PropTypes.bool,
    isSaving: PropTypes.bool,
    saveTerms: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getSaveTermsErrors,
    initialValues: selectors.getInitialValuesTerms,
    isFetchingTerms: selectors.getIsFetchingTerms,
    isSaving: selectors.getIsSavingTerms,
});

const mapDispatchToProps = (dispatch) => ({
    saveTerms: (values) =>
        dispatch(actions.saveTermsAndConditions.save(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsForm);
