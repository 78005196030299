import React from 'react';

import { useTranslation } from 'react-i18next';

import TabsSystem from 'common/components/TabsSystem';
import { INSURANCE_TYPE } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissions';
import { getFinancierType, getIsCiBanco } from 'common/utils/session';

import configurationDocuments from '../configurationDocuments';
import configurationInsurances from '../configurationInsurances';

const Container = () => {
    const { t } = useTranslation();
    const financierType = getFinancierType();
    const isCibanco = getIsCiBanco();

    const tabs = [
        {
            content: <configurationDocuments.Container />,
            id: 'documents',
            label: t('Document files'),
            requiredPermissions: [
                PERMISSION_LIST.ALLIANCE_DOCUMENTS_PERMISSION,
                PERMISSION_LIST.APPLICATION_DOCUMENTS_PERMISSION,
            ],
        },
        {
            content: <configurationInsurances.Container />,
            hidden: financierType === INSURANCE_TYPE || isCibanco,
            id: 'insurances',
            label: 'Cobertura',
            requiredPermissions: [
                PERMISSION_LIST.INSURANCE_DOCUMENTS_PERMISSION,
            ],
        },
    ];

    return (
        <TabsSystem baseUrl="configuration" tabs={tabs} title={t('Setting')} />
    );
};

export default Container;
