import React from 'react';

import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import ActionsFormatter from './ActionsFormatter';

const handleProductType = (type) =>
    type === 'special' ? i18next.t('Especial') : i18next.t('Public');

const ProductColumns = ({
    canModify,
    filterData,
    handleClickBulkItems,
    handleClickDuplicate,
    handleClickPreview,
    handleClickUpdate,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    return [
        {
            align: 'left',
            editable: canModify,
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography
                        onClick={() => handleClickPreview(row)}
                        sx={{ cursor: 'pointer' }}
                        variant="caption"
                    >
                        {row.name}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            field: 'type',
            headerName: t('Type'),
            hide: !isEmpty(rowModesModel),
            renderCell: (params) => {
                const { row } = params;
                return handleProductType(row?.type);
            },
            width: 180,
        },
        {
            align: 'left',
            field: 'created_at',
            headerName: t('Creation date'),
            hide: !isEmpty(rowModesModel),
            width: 180,
        },
        {
            align: 'left',
            field: '',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ActionsFormatter
                        canDelete={true}
                        canModify={true}
                        filterData={filterData}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickDuplicate={handleClickDuplicate}
                        handleClickUpdate={handleClickUpdate}
                        row={row}
                        rowModesModel={rowModesModel}
                        selectedRowId={selectedRowId}
                        setRowModesModel={setRowModesModel}
                        setSelectedRowId={setSelectedRowId}
                    />
                );
            },
            sortable: false,
            width: 180,
        },
    ];
};

ProductColumns.propTypes = {
    canUpdate: PropTypes.bool,
    handleClickBulkItems: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleClickPreview: PropTypes.func,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductColumns;
