import React, { useContext, useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FolderIcon from '@mui/icons-material/Folder';
import InfoIcon from '@mui/icons-material/Info';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Alert,
    Box,
    Button,
    Card,
    Chip,
    Container as MaterialContainer,
    FormGroup,
    Grid,
    IconButton,
    Link,
    Skeleton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TabPanel,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from 'sunwise-ui';

import {
    CardKeyValue,
    CompanyInformationCard,
    DocumentCard,
    HeaderContainer,
    StepperTimeline,
    SupportContactCard,
    TitleIcon,
} from 'common/components';
import {
    APPROVED_STATUS,
    CANCELLED_STATUS,
    CLOSED_STATUS,
    CONDITIONED_APPROVED_STATUS,
    CONTRACT_TYPE,
    DOCUMENTS_TYPE,
    INCOMPLETE_STATUS,
    INSURANCE_DOCUMENTS_TYPE,
    INSURANCE_TYPE,
    PREAPPROVED_STATUS,
    PROCESS_STATUS,
    PRODUCT_DOCUMENTS_CATEGORY,
    QUOTED_STATUS,
    REQUEST_FORM_TYPE,
    REQUESTED_STATUS,
} from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissions';
import withPermissions from 'common/hocs/withPermissions';
import chat from 'common/modules/chat';
import commentArea from 'common/modules/commentArea';
import * as lisaFilesActions from 'common/modules/lisaFiles/actions';
import privateDocuments from 'common/modules/privateDocuments';
import { LoadingContext } from 'common/utils/contexts';
import {
    cleanJSON,
    getCurrencyLabel,
    numberFormat,
} from 'common/utils/helpers';
import { getChatsConfig } from 'common/utils/session';

import * as actions from './actions';
import CardAssignUser from './components/CardAssignUser';
import CardConsumption from './components/CardConsumption';
import ContactFileCard from './components/ContactFileCard';
import CreditRequestSummaryCard from './components/CreditRequestSummaryCard';
import LocationCard from './components/LocationCard';
import ProposalItem from './components/ProposalItem';
import { NAME } from './constants';
import { getStatusButtonProps } from './helpers';
import * as selectors from './selectors';

const Container = ({
    consumptionPdfs,
    contractDocuments,
    contractDocumentsList,
    creditApplication,
    creditBureauData,
    documents,
    documentsList,
    downloaConsumptionsCSV,
    downloadFiles,
    downloadFinancialData,
    fetchConsumptionPdfs,
    fetchContractDocuments,
    fetchCreditApplication,
    fetchCreditBureauData,
    fetchDocuments,
    fetchInsuranceDocuments,
    fetchProposalBackups,
    fetchRequestFormDocuments,
    fetchRequiredDocumentsList,
    getPermissionsByCode,
    handleClickCloseModalContact,
    handleClickUpdate,
    handleDownloadLisaFile,
    handleOnChangeStatusFile,
    handleOnRejectFile,
    insuranceDocuments,
    insuranceDocumentsList,
    isDeletingDocument,
    isDownloading,
    isFetchingContractDocuments,
    isFetchingCreditApp,
    isFetchingDocuments,
    isFetchingInsuranceDocuments,
    isFetchingProposalBackups,
    isFetchingRequestFormDocuments,
    isOpenModalContact,
    isSavingDocument,
    isUploadingFile,
    match,
    prepareApproveCredit,
    prepareCancelCredit,
    prepareChangeStatus,
    prepareCloseCredit,
    prepareCreditUpdateForm,
    prepareDeleteDocumentFile,
    preparePreApproveCredit,
    proposalBackups,
    requestFormDocuments,
    requestFormDocumentsList,
    saveDocument,
    uploadDocumentFile,
    uploadedFile,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [selectedFile, setSelectedFile] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState('general');
    const handleChange = (_, newValue) => setSelectedTab(newValue);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (match?.params.id) fetchCreditApplication(match?.params.id);
    }, [match?.params.id]);

    useEffect(() => {
        setSelectedFile(uploadedFile);
    }, [uploadedFile]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingDocument) {
            loadingContext.openLoading(t('Saving'));
        } else if (isUploadingFile) {
            loadingContext.openLoading(t('Uploading file'));
        } else {
            loadingContext.closeLoading();
        }
    }, [isSavingDocument, isUploadingFile]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isDeletingDocument)
            loadingContext.openLoading(t('Deleting document'));
        else loadingContext.closeLoading();
    }, [isDeletingDocument]);

    useEffect(() => {
        if (!creditApplication.id) return;
        getRequiredDocumentsList();
        if (isEmpty(get(creditApplication, 'commercial_offer', {}))) return;
        fetchCreditBureauData(
            get(creditApplication, 'commercial_offer.commercial_offer_id', null)
        );
        if (!isEmpty(get(creditApplication, 'project', {})))
            fetchConsumptionPdfs(
                get(
                    get(creditApplication, 'project', {}),
                    'customer_energy_consumption.id',
                    null
                )
            );
    }, [creditApplication]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isDownloading) loadingContext.openLoading(t('Downloading'));
        else loadingContext.closeLoading();
    }, [isDownloading]);

    const commercialOffer = get(creditApplication, 'commercial_offer', {});

    useEffect(() => {
        if (get(commercialOffer, 'commercial_offer_id', ''))
            fetchProposalBackups(
                get(commercialOffer, 'commercial_offer_id', '')
            );
    }, [commercialOffer]);

    const annualPayment = get(
        commercialOffer,
        'commercial_offer_line_consumption_proposal',
        []
    ).reduce((acc, current) => {
        const parsedObject = JSON.parse(current.info_price_consumtion);
        const sum = Object.keys(parsedObject).reduce(
            (sumAcc, currentKey) => sumAcc + parsedObject[currentKey],
            0
        );
        return acc + sum;
    }, 0);

    const creditId = get(creditApplication, 'id', null);

    const alliance = get(creditApplication, 'alliance', {});

    const currencyLabel = getCurrencyLabel(
        get(commercialOffer, 'commercial_offer_complements.type_change', 0)
    );

    const installerInfo = get(creditApplication, 'installer', {});
    const clientInfo = get(creditApplication, 'client', {});
    const projectInfo = get(creditApplication, 'project', {});
    const creditItem = get(creditApplication, 'credit_item', {});
    const creditType = get(creditItem, 'credit_type', 0);
    const jsonValues =
        creditItem && creditItem.json_values
            ? JSON.parse(cleanJSON(get(creditItem, 'json_values', '{}')))
            : {};
    const primaTotal = get(jsonValues, 'prima_total', 0);
    const subTotal = get(jsonValues, 'subtotal', 0);

    const supportContacts = get(
        creditApplication,
        'client.contacts_support',
        []
    ).map(({ emails, support_contact, telephones }) => ({
        company_role: support_contact.company_position || '',
        email: get(emails, '[0].email', ''),
        first_name: support_contact.name,
        id: support_contact.id,
        last_name: support_contact.first_lastname,
        phone: get(telephones, '[0].telephone', ''),
    }));
    const { chat_documents } = getChatsConfig();

    const getRequiredDocumentsList = () =>
        fetchRequiredDocumentsList(creditApplication.id);

    const handleSaveDocument = (values, callback) =>
        saveDocument(
            {
                ...values,
                creditApplicationId: match?.params?.id,
                credit_commercial_offer: creditApplication.id,
                installerId: installerInfo.id,
            },
            callback
        );

    const handleOnClickApproval = () =>
        prepareApproveCredit(match?.params?.id, () =>
            fetchCreditApplication(match?.params?.id)
        );

    const handleOnClickPreApproval = () =>
        preparePreApproveCredit(match?.params?.id, () =>
            fetchCreditApplication(match?.params?.id)
        );

    const handleOnClickClose = () =>
        prepareCloseCredit(match?.params?.id, () =>
            fetchCreditApplication(match?.params?.id)
        );

    const handleOnClickCancel = () =>
        prepareCancelCredit(match?.params?.id, () =>
            fetchCreditApplication(match?.params?.id)
        );

    const handleOnClickTimeLineItem = (item) => {
        prepareChangeStatus(
            { creditId: match?.params?.id, ...item },
            () => fetchCreditApplication(match?.params?.id),
            loadingContext
        );
    };

    const financier = { id: creditApplication.financier };

    const { canModify: canModifyInstaller, canView: canViewInstaller } =
        getPermissionsByCode(PERMISSION_LIST.ALLIANCE_REQUESTS_PERMISSION);
    const { canModify } = getPermissionsByCode(
        PERMISSION_LIST.ALLIANCE_PRODUCT_APPLICATIONS_PERMISSION
    );

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Box alignItems="center" display="flex" gap={2}>
                    <TitleIcon title={t('Application file')} />
                    {commercialOffer?.is_editing && (
                        <Tooltip
                            title={t(
                                'The installer is making adjustments to the proposal, upon completion the file will return to the process for review'
                            )}
                        >
                            <Chip
                                icon={<InfoIcon fontSize="small" />}
                                label={t('In edition')}
                                sx={{
                                    '&.MuiChip-filled': {
                                        background: '#002438',
                                        borderColor: '#002438',
                                        color: '#ffffff',
                                        fontWeight: 600,
                                    },
                                    '& .MuiChip-icon': { color: '#ffffff' },
                                }}
                            />
                        </Tooltip>
                    )}
                </Box>
            </HeaderContainer>

            {!isLoading &&
                alliance &&
                typeof alliance.status !== 'undefined' &&
                alliance.status !== CLOSED_STATUS && (
                    <Alert severity="warning">
                        {t(
                            'This request does not yet have an alliance enabled'
                        )}
                    </Alert>
                )}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    onChange={handleChange}
                    scrollButtons="auto"
                    value={selectedTab}
                    variant="scrollable"
                >
                    <Tab
                        id="tab-general"
                        key="tab-general"
                        label={t('General')}
                        value="general"
                    />
                    <Tab
                        id="tab-project-data"
                        key="tab-project-data"
                        label={t('Project information')}
                        value="project-data"
                    />
                </Tabs>
            </Box>

            <Box>
                <TabPanel
                    key="tab-general"
                    selectedTab={selectedTab}
                    value="general"
                >
                    <Grid container>
                        <Grid item lg={12} xs={18}>
                            <Box mb={2}>
                                <CardAssignUser
                                    canModify={canModify}
                                    creditId={creditId}
                                    financialAdvisor={get(
                                        creditApplication,
                                        'financial_advisor',
                                        null
                                    )}
                                    userTracking={get(
                                        creditApplication,
                                        'user_tracking.user',
                                        null
                                    )}
                                />
                            </Box>

                            <Grid container>
                                <Grid item lg={9} xs={18}>
                                    <ContactFileCard
                                        canModify={canModify}
                                        client={clientInfo}
                                        creditId={creditId}
                                        handleClickCloseModalContact={
                                            handleClickCloseModalContact
                                        }
                                        handleClickUpdate={handleClickUpdate}
                                        isLoadingContact={isFetchingCreditApp}
                                        isOpenModalContact={isOpenModalContact}
                                        systemGeneration={get(
                                            commercialOffer,
                                            'commercial_offer_value_consumption[0].sistem_generation',
                                            0
                                        )}
                                        systemSize={get(
                                            commercialOffer,
                                            'commercial_offer_value_consumption[0].system_size',
                                            0
                                        )}
                                    />
                                </Grid>

                                <Grid item lg={9} xs={18}>
                                    <CreditRequestSummaryCard
                                        canModify={canModify}
                                        commercialOfferId={get(
                                            commercialOffer,
                                            'commercial_offer_id',
                                            ''
                                        )}
                                        creditItem={creditItem}
                                        downloadFinancialData={
                                            downloadFinancialData
                                        }
                                        financierProduct={get(
                                            creditApplication,
                                            'financier_product',
                                            ''
                                        )}
                                        parameters={get(
                                            commercialOffer,
                                            'commercial_offer_parameters[0]',
                                            {}
                                        )}
                                        prepareCreditUpdateForm={() =>
                                            prepareCreditUpdateForm(
                                                creditApplication
                                            )
                                        }
                                        primaTotal={primaTotal}
                                        showEditButton={
                                            ![
                                                CANCELLED_STATUS,
                                                APPROVED_STATUS,
                                                CLOSED_STATUS,
                                            ].includes(creditApplication.status)
                                        }
                                        subTotal={subTotal}
                                        total={get(
                                            commercialOffer,
                                            'commercial_offer_total',
                                            0
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Box mb={2}>
                                <Card>
                                    <Card.Header>
                                        <Typography
                                            fontWeight="bold"
                                            variant="body2"
                                        >
                                            {t('Chat')}
                                        </Typography>
                                    </Card.Header>

                                    <Card.Body>
                                        <chat.Container
                                            canSendMessage={chat_documents}
                                            itemId={match?.params.id}
                                            key={`credit-chat-${match?.params.id}`}
                                            type="credit"
                                            user={financier}
                                        />
                                    </Card.Body>
                                </Card>
                            </Box>

                            <Grid container>
                                <Grid item xs={18} md={9}>
                                    <privateDocuments.Container
                                        canModify={canModify}
                                        fetchId={match?.params.id}
                                        installer={creditApplication.installer}
                                        profileId={match?.params.id}
                                        type={PRODUCT_DOCUMENTS_CATEGORY}
                                    />
                                </Grid>

                                <Grid item xs={18} md={9}>
                                    <CompanyInformationCard
                                        alliance={alliance}
                                        business_name={
                                            installerInfo.business_name
                                        }
                                        canModifyInstaller={canModifyInstaller}
                                        canViewInstaller={canViewInstaller}
                                        image={installerInfo.image}
                                        legal_representative={
                                            installerInfo.legal_representative
                                        }
                                        name={installerInfo.name}
                                        phone={installerInfo.phone}
                                        rfc={installerInfo.rfc}
                                        web_page={installerInfo.web_page}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={6} xs={18}>
                            <Card>
                                <Card.Body>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {t('Application')}
                                    </Typography>

                                    {[CANCELLED_STATUS].includes(
                                        creditApplication.status
                                    ) && (
                                        <Alert severity="error">
                                            {t(
                                                'The status of this request is "{{status}}"',
                                                {
                                                    status: creditApplication.status,
                                                }
                                            )}
                                        </Alert>
                                    )}

                                    <Box sx={{ py: 2 }} mb={2}>
                                        {/* 0 - Solicitado, 
                                            8 - Incompleto,
                                            1 - Proceso,
                                            7 - Pre-viabilidad,
                                            3 - Aprobado,
                                            4 - Otorgado
                                        */}
                                        <StepperTimeline
                                            canModify={canModify}
                                            onClick={handleOnClickTimeLineItem}
                                            status={creditApplication?.status}
                                        />
                                    </Box>

                                    {creditBureauData && (
                                        <Card>
                                            <Card.Header>
                                                <Box
                                                    alignItems="center"
                                                    display="flex"
                                                >
                                                    <Typography
                                                        fontWeight="bold"
                                                        variant="body2"
                                                    >
                                                        {t('Credit bureau')}
                                                    </Typography>
                                                    <Box
                                                        alignItems="center"
                                                        display="flex"
                                                        sx={{ ml: 'auto' }}
                                                    >
                                                        <CircleIcon
                                                            style={{
                                                                color: creditBureauData?.is_standby
                                                                    ? 'rgb(206, 212, 220)'
                                                                    : '#26f2ad',
                                                            }}
                                                        />

                                                        {creditBureauData?.pdf_archive && (
                                                            <IconButton
                                                                href={
                                                                    creditBureauData?.pdf_archive
                                                                }
                                                                target="_blank"
                                                            >
                                                                <FileDownloadIcon fontSize="small" />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Card.Header>

                                            <Card.Body>
                                                <Box
                                                    alignItems="center"
                                                    display="flex"
                                                >
                                                    <CheckCircleIcon
                                                        style={{
                                                            color: '#26f2ad',
                                                        }}
                                                    />
                                                    {t('Request sent to')}
                                                </Box>

                                                <FormGroup
                                                    sx={{ mb: 1, mt: 1 }}
                                                >
                                                    <TextField
                                                        disabled
                                                        fullWidth
                                                        placeholder={t('Email')}
                                                        label={t('Email')}
                                                        value={
                                                            creditBureauData?.email
                                                        }
                                                    />
                                                </FormGroup>

                                                <Alert
                                                    style={getStatusButtonProps(
                                                        creditBureauData?.status
                                                    )}
                                                    severity="info"
                                                >
                                                    {
                                                        creditBureauData?.status
                                                            ?.value
                                                    }
                                                </Alert>
                                            </Card.Body>
                                        </Card>
                                    )}

                                    <DocumentCard
                                        canModify={canModify}
                                        documents={requestFormDocuments}
                                        documentType={
                                            commentArea.CREDIT_DOCUMENT_TYPE
                                        }
                                        fetchDocuments={
                                            fetchRequestFormDocuments
                                        }
                                        fetchRequiredDocumentsList={
                                            getRequiredDocumentsList
                                        }
                                        handleOnChangeStatusFile={
                                            handleOnChangeStatusFile
                                        }
                                        handleOnClickDeleteFile={
                                            prepareDeleteDocumentFile
                                        }
                                        handleOnRejectFile={handleOnRejectFile}
                                        isFetching={
                                            isFetchingRequestFormDocuments
                                        }
                                        moduleName={NAME}
                                        onChangeFileStatus={
                                            getRequiredDocumentsList
                                        }
                                        onClickDownload={() =>
                                            downloadFiles(
                                                match?.params?.id,
                                                REQUEST_FORM_TYPE,
                                                'formatos-de-solicitud-de-alta'
                                            )
                                        }
                                        onSave={handleSaveDocument}
                                        requiredDocuments={
                                            requestFormDocumentsList
                                        }
                                        selectedFile={selectedFile}
                                        setSelectedFile={setSelectedFile}
                                        showChat={chat_documents}
                                        title={t('Registration Request Form')}
                                        uploadDocumentFile={uploadDocumentFile}
                                        user={financier}
                                        userType={
                                            commentArea.FINANCIER_USER_TYPE
                                        }
                                        visible={creditType !== INSURANCE_TYPE}
                                    />

                                    <DocumentCard
                                        canModify={canModify}
                                        documents={insuranceDocuments}
                                        documentType={
                                            commentArea.CREDIT_DOCUMENT_TYPE
                                        }
                                        fetchDocuments={fetchInsuranceDocuments}
                                        fetchRequiredDocumentsList={
                                            getRequiredDocumentsList
                                        }
                                        handleOnChangeStatusFile={
                                            handleOnChangeStatusFile
                                        }
                                        handleOnClickDeleteFile={
                                            prepareDeleteDocumentFile
                                        }
                                        handleOnRejectFile={handleOnRejectFile}
                                        isFetching={
                                            isFetchingInsuranceDocuments
                                        }
                                        moduleName={NAME}
                                        onChangeFileStatus={
                                            getRequiredDocumentsList
                                        }
                                        onClickDownload={() =>
                                            downloadFiles(
                                                match?.params?.id,
                                                INSURANCE_DOCUMENTS_TYPE,
                                                'formatos-de-solicitud-de-seguro'
                                            )
                                        }
                                        onSave={handleSaveDocument}
                                        requiredDocuments={
                                            insuranceDocumentsList
                                        }
                                        selectedFile={selectedFile}
                                        setSelectedFile={setSelectedFile}
                                        showChat={chat_documents}
                                        title={t('Insurance application form')}
                                        uploadDocumentFile={uploadDocumentFile}
                                        user={financier}
                                        userType={
                                            commentArea.FINANCIER_USER_TYPE
                                        }
                                        visible={creditType === INSURANCE_TYPE}
                                    />

                                    <DocumentCard
                                        canModify={canModify}
                                        documents={documents}
                                        documentType={
                                            commentArea.CREDIT_DOCUMENT_TYPE
                                        }
                                        fetchDocuments={fetchDocuments}
                                        fetchRequiredDocumentsList={
                                            getRequiredDocumentsList
                                        }
                                        handleOnChangeStatusFile={
                                            handleOnChangeStatusFile
                                        }
                                        handleOnClickDeleteFile={
                                            prepareDeleteDocumentFile
                                        }
                                        handleOnRejectFile={handleOnRejectFile}
                                        isFetching={isFetchingDocuments}
                                        moduleName={NAME}
                                        onChangeFileStatus={
                                            getRequiredDocumentsList
                                        }
                                        onClickDownload={() =>
                                            downloadFiles(
                                                match?.params?.id,
                                                DOCUMENTS_TYPE,
                                                'documentos'
                                            )
                                        }
                                        onSave={handleSaveDocument}
                                        requiredDocuments={documentsList}
                                        selectedFile={selectedFile}
                                        setSelectedFile={setSelectedFile}
                                        showChat={chat_documents}
                                        title={t('Document', { count: 2 })}
                                        uploadDocumentFile={uploadDocumentFile}
                                        user={financier}
                                        userType={
                                            commentArea.FINANCIER_USER_TYPE
                                        }
                                    />

                                    {[
                                        APPROVED_STATUS,
                                        CLOSED_STATUS,
                                        CONDITIONED_APPROVED_STATUS,
                                    ].includes(creditApplication.status) && (
                                        <DocumentCard
                                            canModify={canModify}
                                            documentType={
                                                commentArea.CREDIT_DOCUMENT_TYPE
                                            }
                                            documents={contractDocuments}
                                            fetchDocuments={
                                                fetchContractDocuments
                                            }
                                            fetchRequiredDocumentsList={
                                                getRequiredDocumentsList
                                            }
                                            handleOnChangeStatusFile={
                                                handleOnChangeStatusFile
                                            }
                                            handleOnClickDeleteFile={
                                                prepareDeleteDocumentFile
                                            }
                                            handleOnRejectFile={
                                                handleOnRejectFile
                                            }
                                            isFetching={
                                                isFetchingContractDocuments
                                            }
                                            moduleName={NAME}
                                            onChangeFileStatus={
                                                getRequiredDocumentsList
                                            }
                                            onClickDownload={() =>
                                                downloadFiles(
                                                    match?.params?.id,
                                                    CONTRACT_TYPE,
                                                    'contratos'
                                                )
                                            }
                                            onSave={handleSaveDocument}
                                            requiredDocuments={
                                                contractDocumentsList
                                            }
                                            setSelectedFile={setSelectedFile}
                                            selectedFile={selectedFile}
                                            showChat={chat_documents}
                                            title={t('Contract', { count: 2 })}
                                            uploadDocumentFile={
                                                uploadDocumentFile
                                            }
                                            user={financier}
                                            userType={
                                                commentArea.FINANCIER_USER_TYP
                                            }
                                        />
                                    )}

                                    {[
                                        INCOMPLETE_STATUS,
                                        PROCESS_STATUS,
                                        REQUESTED_STATUS,
                                    ].includes(creditApplication.status) &&
                                        canModify && (
                                            <Button
                                                color="success"
                                                disabled={
                                                    alliance &&
                                                    alliance.status !==
                                                        CLOSED_STATUS
                                                }
                                                onClick={
                                                    handleOnClickPreApproval
                                                }
                                                sx={{ mb: 1, width: '100%' }}
                                            >
                                                {t(
                                                    'Pre-feasibility Application'
                                                )}
                                            </Button>
                                        )}

                                    {[PREAPPROVED_STATUS].includes(
                                        creditApplication.status
                                    ) &&
                                        canModify && (
                                            <Button
                                                color="success"
                                                disabled={
                                                    alliance &&
                                                    alliance.status !==
                                                        CLOSED_STATUS
                                                }
                                                onClick={handleOnClickApproval}
                                                sx={{ mb: 1, width: '100%' }}
                                            >
                                                {t('Approve request')}
                                            </Button>
                                        )}

                                    {[
                                        APPROVED_STATUS,
                                        CONDITIONED_APPROVED_STATUS,
                                    ].includes(creditApplication.status) &&
                                        canModify && (
                                            <Button
                                                color="info"
                                                disabled={
                                                    (alliance &&
                                                        alliance.status !==
                                                            CLOSED_STATUS) ||
                                                    creditApplication.status ===
                                                        CONDITIONED_APPROVED_STATUS
                                                }
                                                onClick={handleOnClickClose}
                                                sx={{ mb: 1, width: '100%' }}
                                            >
                                                {t('Grant request')}
                                            </Button>
                                        )}

                                    {[
                                        APPROVED_STATUS,
                                        CLOSED_STATUS,
                                        CONDITIONED_APPROVED_STATUS,
                                        INCOMPLETE_STATUS,
                                        PREAPPROVED_STATUS,
                                        PROCESS_STATUS,
                                        QUOTED_STATUS,
                                        REQUESTED_STATUS,
                                    ].includes(creditApplication.status) &&
                                        canModify && (
                                            <Button
                                                color="error"
                                                disabled={
                                                    alliance &&
                                                    alliance.status !==
                                                        CLOSED_STATUS
                                                }
                                                onClick={handleOnClickCancel}
                                                sx={{ mb: 1, width: '100%' }}
                                            >
                                                {t('Cancel request')}
                                            </Button>
                                        )}
                                </Card.Body>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel
                    key="tab-project-data"
                    selectedTab={selectedTab}
                    value="project-data"
                >
                    <Grid container>
                        <Grid item lg={9} xs={18}>
                            <Box mb={2}>
                                <Card>
                                    <Card.Header>
                                        <TitleIcon
                                            fontWeight="bold"
                                            icon={
                                                <FolderIcon
                                                    fontSize="medium"
                                                    sx={{
                                                        color: 'primary.main',
                                                    }}
                                                />
                                            }
                                            title={projectInfo?.name}
                                            variant="body2"
                                        />
                                    </Card.Header>

                                    <Card.Body>
                                        <ProposalItem
                                            creditId={creditId}
                                            proposalId={get(
                                                commercialOffer,
                                                'commercial_offer_id',
                                                null
                                            )}
                                            proposalName={get(
                                                commercialOffer,
                                                'name',
                                                null
                                            )}
                                            createdAt={projectInfo?.created_at}
                                        />
                                    </Card.Body>
                                </Card>
                            </Box>

                            <Box mb={2}>
                                <Card>
                                    <Card.Header>
                                        <Typography
                                            fontWeight="bold"
                                            variant="body2"
                                        >
                                            {t('Proposal history')}
                                        </Typography>
                                    </Card.Header>
                                    <Card.Body>
                                        {isFetchingProposalBackups && (
                                            <Skeleton
                                                variant="rectangular"
                                                height={30}
                                            />
                                        )}

                                        {!isEmpty(proposalBackups) && (
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            {t('Name')}
                                                        </TableCell>

                                                        <TableCell>
                                                            {t('Justification')}
                                                        </TableCell>

                                                        <TableCell align="right">
                                                            {t('Creation date')}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {proposalBackups.map(
                                                        (row) => (
                                                            <TableRow
                                                                key={row.name}
                                                                sx={{
                                                                    '&:last-child td, &:last-child th':
                                                                        {
                                                                            border: 0,
                                                                        },
                                                                }}
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <Link
                                                                        color="secondary"
                                                                        component={
                                                                            RouterLink
                                                                        }
                                                                        to={`/credits/${creditId}/preview/${row.id}`}
                                                                    >
                                                                        <Typography
                                                                            fontWeight="bold"
                                                                            variant="body2"
                                                                        >
                                                                            {
                                                                                row.name
                                                                            }
                                                                        </Typography>
                                                                    </Link>
                                                                </TableCell>

                                                                <TableCell>
                                                                    {
                                                                        row.justification_message
                                                                    }
                                                                </TableCell>

                                                                <TableCell align="right">
                                                                    {
                                                                        row.created
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Box>

                            <Box mb={2}>
                                <LocationCard
                                    creditId={creditId}
                                    location={get(
                                        creditApplication,
                                        'location',
                                        {}
                                    )}
                                />
                            </Box>

                            <Box mb={2}>
                                <SupportContactCard
                                    contacts={supportContacts}
                                />
                            </Box>
                        </Grid>

                        <Grid item lg={9} xs={18}>
                            <Box mb={2}>
                                <CardConsumption
                                    consumptionPdfs={consumptionPdfs}
                                    handleDownloadCSV={downloaConsumptionsCSV}
                                    handleDownloadLisaFile={
                                        handleDownloadLisaFile
                                    }
                                    items={get(
                                        commercialOffer,
                                        'commercial_offer_line_consumption_proposal',
                                        []
                                    )}
                                    project={projectInfo}
                                />
                            </Box>

                            <Grid container>
                                <Grid item xs={18} md>
                                    <CardKeyValue
                                        append={currencyLabel.toUpperCase()}
                                        prepend="$"
                                        title={t('Average monthly payment')}
                                        value={numberFormat(
                                            annualPayment / 12,
                                            { style: 'currency' }
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={18} md>
                                    <CardKeyValue
                                        append={currencyLabel.toUpperCase()}
                                        prepend="$"
                                        title={t('Annual payment')}
                                        value={numberFormat(annualPayment, {
                                            style: 'currency',
                                        })}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        </MaterialContainer>
    );
};

Container.propTypes = {
    consumptionPdfs: PropTypes.array,
    contractDocuments: PropTypes.array,
    contractDocumentsList: PropTypes.array,
    creditApplication: PropTypes.object,
    creditBureauData: PropTypes.object,
    documents: PropTypes.array,
    documentsList: PropTypes.array,
    downloaConsumptionsCSV: PropTypes.func,
    downloadFiles: PropTypes.func,
    downloadFinancialData: PropTypes.func,
    fetchConsumptionPdfs: PropTypes.func,
    fetchContractDocuments: PropTypes.func,
    fetchCreditApplication: PropTypes.func,
    fetchCreditBureauData: PropTypes.func,
    fetchDocuments: PropTypes.func,
    fetchInsuranceDocuments: PropTypes.func,
    fetchProposalBackups: PropTypes.func,
    fetchRequestFormDocuments: PropTypes.func,
    fetchRequiredDocumentsList: PropTypes.func,
    getPermissionsByCode: PropTypes.func,
    handleClickCloseModalContact: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleDownloadLisaFile: PropTypes.func,
    handleOnChangeStatusFile: PropTypes.func,
    handleOnRejectFile: PropTypes.func,
    insuranceDocuments: PropTypes.array,
    insuranceDocumentsList: PropTypes.array,
    isDeletingDocument: PropTypes.bool,
    isDownloading: PropTypes.bool,
    isFetchingContractDocuments: PropTypes.bool,
    isFetchingCreditApp: PropTypes.bool,
    isFetchingDocuments: PropTypes.bool,
    isFetchingInsuranceDocuments: PropTypes.bool,
    isFetchingProposalBackups: PropTypes.bool,
    isFetchingRequestFormDocuments: PropTypes.bool,
    isOpenModalContact: PropTypes.bool,
    isSavingDocument: PropTypes.bool,
    isUploadingFile: PropTypes.bool,
    match: PropTypes.object,
    prepareApproveCredit: PropTypes.func,
    prepareCancelCredit: PropTypes.func,
    prepareChangeStatus: PropTypes.func,
    prepareCloseCredit: PropTypes.func,
    prepareCreditUpdateForm: PropTypes.func,
    prepareDeleteDocumentFile: PropTypes.func,
    preparePreApproveCredit: PropTypes.func,
    proposalBackups: PropTypes.array,
    requestFormDocuments: PropTypes.array,
    requestFormDocumentsList: PropTypes.array,
    saveDocument: PropTypes.func,
    uploadDocumentFile: PropTypes.func,
    uploadedFile: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    consumptionPdfs: selectors.getConsumptionPdfsData,
    contractDocuments: selectors.getContractDocumentsData,
    contractDocumentsList: selectors.getContractDocumentsList,
    creditApplication: selectors.getCreditApplicationData,
    creditBureauData: selectors.getCreditBureauData,
    documents: selectors.getDocumentsData,
    documentsList: selectors.getDocumentsList,
    insuranceDocuments: selectors.getInsuranceDocumentsData,
    insuranceDocumentsList: selectors.getInsuranceDocumentsList,
    isDeletingDocument: selectors.getIsDeletingDocument,
    isDownloading: selectors.getIsDownloading,
    isFetching: selectors.getDocumentsIsFetching,
    isFetchingContractDocuments: selectors.getContractDocumentsIsFetching,
    isFetchingCreditApp: selectors.getCreditApplicationIsFetching,
    isFetchingDocuments: selectors.getDocumentsIsFetching,
    isFetchingInsuranceDocuments: selectors.getInsuranceDocumentsIsFetching,
    isFetchingProposalBackups: selectors.getIsFetchingProposalBackups,
    isFetchingRequestFormDocuments: selectors.getRequestFormDocumentsIsFetching,
    isOpenModalContact: selectors.getIsOpenModalContact,
    isSavingDocument: selectors.getSaveDocumentIsSaving,
    isUploadingFile: selectors.isFileUploading,
    proposalBackups: selectors.getProposalBackupsData,
    requestFormDocuments: selectors.getRequestFormDocumentsData,
    requestFormDocumentsList: selectors.getRequestFormDocumentsList,
    uploadedFile: selectors.getUploadedFileData,
});

const mapDispatchToProps = (dispatch) => ({
    downloaConsumptionsCSV: (id) =>
        dispatch(actions.downloaConsumptionsCSV(id)),
    downloadFiles: (id, type, fileName) =>
        dispatch(actions.downloadFiles(id, type, fileName)),
    downloadFinancialData: (proposalId) =>
        dispatch(actions.downloadFinancialData(proposalId)),
    fetchConsumptionPdfs: (id) => dispatch(actions.fetchConsumptionPdfs(id)),
    fetchContractDocuments: () => dispatch(actions.fetchContractDocuments()),
    fetchCreditApplication: (id) =>
        dispatch(actions.fetchCreditApplication(id)),
    fetchCreditBureauData: (id) => dispatch(actions.fetchCreditBureauData(id)),
    fetchDocuments: () => dispatch(actions.fetchDocuments()),
    fetchInsuranceDocuments: () => dispatch(actions.fetchInsuranceDocuments()),
    fetchProposalBackups: (proposalId) =>
        dispatch(actions.fetchProposalBackups(proposalId)),
    fetchRequestFormDocuments: () =>
        dispatch(actions.fetchRequestFormDocuments()),
    fetchRequiredDocumentsList: (id) =>
        dispatch(actions.fetchRequiredDocumentsList(id)),
    handleClickCloseModalContact: () =>
        dispatch(actions.toggleModalContact(false)),
    handleClickUpdate: (item) => dispatch(actions.prepareUpdate(item)),
    handleDownloadLisaFile: (file_name) =>
        dispatch(lisaFilesActions.downloadFileFromName(file_name)),
    handleOnChangeStatusFile: (file, status, callback) =>
        dispatch(actions.changeStatusFile(file, status, callback)),
    handleOnRejectFile: (file, callback) =>
        dispatch(actions.rejectFile(file, callback)),
    prepareApproveCredit: (id, callback) =>
        dispatch(actions.prepareApproveCredit(id, callback)),
    prepareCancelCredit: (id, callback) =>
        dispatch(actions.prepareCancelCredit(id, callback)),
    prepareChangeStatus: (item, callback, loadingContext) =>
        dispatch(actions.prepareChangeStatus(item, callback, loadingContext)),
    prepareCloseCredit: (id, callback) =>
        dispatch(actions.prepareCloseCredit(id, callback)),
    prepareCreditUpdateForm: (creditApplication) =>
        dispatch(actions.prepareCreditUpdateForm(creditApplication)),
    prepareDeleteDocumentFile: (document) =>
        dispatch(actions.prepareDeleteDocumentFile(document)),
    preparePreApproveCredit: (id, callback) =>
        dispatch(actions.preparePreApproveCredit(id, callback)),
    saveDocument: (values, callback) =>
        dispatch(actions.saveDocument(values, callback)),
    uploadDocumentFile: (document, file, onSuccess) =>
        dispatch(actions.uploadDocumentFile(document, file, onSuccess)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.ALLIANCE_REQUESTS_PERMISSION,
        PERMISSION_LIST.ALLIANCE_PRODUCT_APPLICATIONS_PERMISSION,
    ]),
    withRouter
)(Container);
