import { updateChatSettings } from 'common/api/companies';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    ACTIVATE_CHAT,
    ACTIVATE_CHAT_FAILURE,
    ACTIVATE_CHAT_SUCCESS,
} from '../actionTypes';
import { configurationDocumentsActions } from '../reducer';

export default (data, financierId, callback) => (dispatch) => {
    dispatch(configurationDocumentsActions[ACTIVATE_CHAT]());

    updateChatSettings(data, financierId)
        .then(() => {
            dispatch(configurationDocumentsActions[ACTIVATE_CHAT_SUCCESS]());
            if (callback) callback();
            showToast();
        })
        .catch((error) => {
            dispatch(
                configurationDocumentsActions[ACTIVATE_CHAT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
