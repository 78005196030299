import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import * as customColumnsSelectors from 'common/modules/customColumnsV2/selectors';

import * as funnelFinancingActions from '../funnelFinancing/actions';
import * as funnelFinancingSelectors from '../funnelFinancing/selectors';

import * as actions from './actions';
import ListItems from './components/ListItems';
import * as selectors from './selectors';

const Container = ({
    companies,
    fetchCompanies,
    fetchRates,
    filterQuotes,
    isFetching,
    isFetchingCompanies,
    isFetchingRates,
    isFetchingTableSettings,
    paginationItems,
    quotes,
    rates,
    tableSettings,
}) => {
    const [filterData, setFilterData] = useState({
        company: '',
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        rateName: 'all',
        searchText: '',
        sortBy: 'desc',
    });
    useEffect(() => {
        fetchCompanies();
        fetchRates();
        filterQuotes(filterData);
    }, []);

    return (
        <Grid container>
            <Grid item xs={18}>
                <ListItems
                    companies={companies}
                    filterData={filterData}
                    filterItems={filterQuotes}
                    isFetching={
                        isFetching ||
                        isFetchingCompanies ||
                        isFetchingRates ||
                        isFetchingTableSettings
                    }
                    items={quotes}
                    paginationData={paginationItems}
                    rates={rates}
                    setFilterData={setFilterData}
                    tableSettings={tableSettings}
                />
            </Grid>
        </Grid>
    );
};

Container.propTypes = {
    companies: PropTypes.array,
    fetchCompanies: PropTypes.func,
    fetchRates: PropTypes.func,
    filterQuotes: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingCompanies: PropTypes.bool,
    isFetchingRates: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    paginationItems: PropTypes.object,
    quotes: PropTypes.array,
    rates: PropTypes.array,
    tableSettings: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    companies: funnelFinancingSelectors.getCompaniesData,
    isFetching: selectors.getIsFetchingQuotes,
    isFetchingCompanies: funnelFinancingSelectors.getCompaniesIsFetching,
    isFetchingRates: selectors.getIsFetchingRates,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    paginationItems: selectors.getQuotesPagination,
    quotes: selectors.getQuotesData,
    rates: selectors.getRatesData,
    tableSettings: customColumnsSelectors.getTableSettingsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompanies: () => dispatch(funnelFinancingActions.fetchCompanies()),
    fetchRates: () => dispatch(actions.fetchRates()),
    filterQuotes: (filterData) => dispatch(actions.filterQuotes(filterData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
