import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from 'sunwise-ui';

import { ReactComponent as InteractiveNotFound } from 'resources/interactiveNotFound.svg';

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px);
    justify-content: center;
    width: 100%;
`;

const NotFound = ({ creditId, fromQuote, isLoading }) => {
    const { t } = useTranslation();
    const url = fromQuote
        ? '/applications/quotes'
        : `/credits/file/${creditId}`;

    if (isLoading) return null;

    return (
        <Container>
            <Grid container justifyContent="center">
                <Grid item md={12} textAlign="center" xs={18}>
                    <InteractiveNotFound />
                </Grid>
            </Grid>

            <Grid container mt={2}>
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                    textAlign="center"
                    xs={18}
                >
                    <Typography
                        fontWeight="bold"
                        sx={{ color: '#3a647f', order: { md: 1, xs: 2 } }}
                        variant="h4"
                    >
                        {t('Oops! Contact your Installer!')}
                    </Typography>

                    <Typography
                        fontWeight="bold"
                        sx={{ order: { md: 2, xs: 1 } }}
                        variant="body2"
                    >
                        {t(
                            'Apparently this proposal is no longer valid or has been removed'
                        )}
                    </Typography>
                    <Box sx={{ order: 3 }}>
                        <Button color="secondary" href={url} variant="text">
                            {t('Back')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

NotFound.propTypes = {
    creditId: PropTypes.string,
    fromQuote: PropTypes.bool,
    isLoading: PropTypes.bool,
};

export default NotFound;
