import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import archiveCoverage from './archiveCoverage';

export default (filterData, item, value, callback) => (dispatch) => {
    const action =
        value === 'active' ? i18next.t('Unarchive') : i18next.t('Archive');
    const name = item.name;

    const dataAlert = {
        confirmText: action,
        messages: [
            i18next.t('Are you really sure about {{action} {{name}}?', {
                action: action.toLowerCase(),
                name,
            }),
        ],
        onSuccess: () =>
            dispatch(archiveCoverage(filterData, item, value, callback)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
