import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider } from 'sunwise-ui';

import {
    ReduxFieldInput,
    ReduxFieldSelect,
} from 'common/components/form/redux';

import * as actions from '../actions';
import { NAME } from '../constants';
import { getLanguageOptions } from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

const TemplateForm = ({ filterData, handleSubmit, save }) => {
    const { t } = useTranslation();
    const handleClickSave = (values) => save(filterData, values);

    return (
        <form>
            <Field
                component={ReduxFieldInput}
                label={`${t('Title')} *`}
                name="name"
                sx={{ mb: 2 }}
            />

            <Field
                component={ReduxFieldSelect}
                label={t('Language')}
                name="language"
                options={getLanguageOptions()}
            />

            <Divider sx={{ mb: 2, mx: -2 }} />

            <Box sx={{ textAlign: 'right' }}>
                <Button onClick={handleSubmit(handleClickSave)}>
                    {t('Save')}
                </Button>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    save: (filterData, values) => dispatch(actions.save(filterData, values)),
});

TemplateForm.propTypes = {
    filterData: PropTypes.object,
    handleSubmit: PropTypes.func,
    save: PropTypes.func,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/template-form`,
    validate,
})(TemplateForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
