import React, { useContext, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import { useTheme } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';
import {
    Button,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from 'sunwise-ui';

import { LoadingContext } from 'common/utils/contexts';

import * as actions from './actions';
import MenuOptions from './components/MenuOptions';
import SideMenuCard from './components/SideMenuCard';
import SmartDocumentModal from './components/SmartDocumentModal';
import { getIsUpdatingContextMessage } from './helpers';
import * as selectors from './selectors';

const Container = ({
    closeDrawer,
    editionDisabled,
    fetchCustomTemplate,
    fetchDocuments,
    handleClickEditButton,
    handleClickProposalItem,
    initTemplate,
    isDisabled,
    isUpdatingCustomTemplate,
    prepareDocumentCreate,
    prepareDocumentDelete,
    prepareDocumentDuplicate,
    prepareDocumentRename,
    proposalDocumentDefault,
    proposalId,
    resetTemplate,
    selectedDocument,
    setEditionDisabled,
    setSelectedDocument,
    smartDocuments,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        fetchDocuments(proposalId);
    }, []);

    useEffect(() => {
        getIsUpdatingContextMessage(
            editionDisabled,
            isUpdatingCustomTemplate,
            loadingContext,
            setEditionDisabled
        );
    }, [isUpdatingCustomTemplate]);

    const handleClickDocumentItem = (document) => {
        const { hasContent, id } = document;
        if (id !== selectedDocument.id) {
            setSelectedDocument(document);
            if (!hasContent)
                fetchCustomTemplate(id, initTemplate, resetTemplate);
        }
        closeDrawer();
    };

    const handleOnClickCreate = () => prepareDocumentCreate(proposalId);

    const selectedDocumentId = get(selectedDocument, 'id', null);

    return (
        <>
            <SideMenuCard
                icon={<ArticleIcon sx={{ color: 'primary.main' }} />}
                title={t('Financing Documents')}
            >
                <List disablePadding>
                    {smartDocuments.map((document) => (
                        <ListItem
                            disabled={
                                isDisabled || document.id === selectedDocumentId
                            }
                            disablePadding
                            key={`smart-document-${document.id}`}
                            secondaryAction={
                                <MenuOptions
                                    document={document}
                                    handleClickDocumentItem={
                                        handleClickDocumentItem
                                    }
                                    handleClickEditButton={
                                        handleClickEditButton
                                    }
                                    handleClickProposalItem={
                                        handleClickProposalItem
                                    }
                                    isDisabled={isDisabled}
                                    prepareDocumentDelete={
                                        prepareDocumentDelete
                                    }
                                    prepareDocumentDuplicate={
                                        prepareDocumentDuplicate
                                    }
                                    prepareDocumentRename={
                                        prepareDocumentRename
                                    }
                                    proposalDocumentDefault={
                                        proposalDocumentDefault
                                    }
                                    proposalId={proposalId}
                                    selectedDocumentId={selectedDocumentId}
                                />
                            }
                            selected={document.id === selectedDocumentId}
                            sx={{
                                borderRadius: '8px',
                                mb: '5px',
                                '&.Mui-selected': {
                                    backgroundColor:
                                        theme.palette.mode === 'dark'
                                            ? '#000'
                                            : 'rgba(31, 60, 83, 0.13)',
                                },
                            }}
                        >
                            <ListItemButton
                                disabled={
                                    isDisabled ||
                                    document.id === selectedDocumentId
                                }
                                onClick={() =>
                                    handleClickDocumentItem({
                                        ...document,
                                        document_reference_id: document.id,
                                        type: types.SMART_DOCUMENTS_TYPE,
                                    })
                                }
                                sx={{ borderRadius: '8px' }}
                            >
                                <ListItemText
                                    primary={document.name}
                                    sx={{
                                        '.MuiListItemText-primary': {
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                        },
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Grid container spacing={0} mt={1}>
                    <Grid item xs sx={{ textAlign: 'center' }}>
                        <Button
                            disabled={isDisabled}
                            fullWidth
                            endIcon={<AddIcon />}
                            onClick={handleOnClickCreate}
                            type="button"
                            variant="outlined"
                        >
                            {t('Add')}
                        </Button>
                    </Grid>
                </Grid>
            </SideMenuCard>

            <SmartDocumentModal
                handleClickDocumentItem={handleClickDocumentItem}
                initTemplate={initTemplate}
                proposalId={proposalId}
                resetTemplate={resetTemplate}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    smartDocuments: selectors.getSmartDocumentsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCustomTemplate: (id, initTemplate, resetTemplate) =>
        dispatch(actions.fetchCustomTemplate(id, initTemplate, resetTemplate)),
    fetchDocuments: (proposalId) =>
        dispatch(actions.fetchDocuments(proposalId)),
    prepareDocumentCreate: (proposalId) =>
        dispatch(actions.prepareDocumentCreate(proposalId)),
    prepareDocumentDelete: (
        handleClickDocumentItem,
        handleClickProposalItem,
        proposalDocumentDefault,
        proposalId,
        smartDocumentId
    ) =>
        dispatch(
            actions.prepareDocumentDelete(
                handleClickDocumentItem,
                handleClickProposalItem,
                proposalDocumentDefault,
                proposalId,
                smartDocumentId
            )
        ),
    prepareDocumentDuplicate: (smartDocumentName, smartDocumentId) =>
        dispatch(
            actions.prepareDocumentDuplicate(smartDocumentName, smartDocumentId)
        ),
    prepareDocumentRename: (smartDocumentName, smartDocumentId) =>
        dispatch(
            actions.prepareDocumentRename(smartDocumentName, smartDocumentId)
        ),
});

Container.propTypes = {
    closeDrawer: PropTypes.func,
    editionDisabled: PropTypes.bool,
    fetchCustomTemplate: PropTypes.func,
    fetchDocuments: PropTypes.func,
    handleClickEditButton: PropTypes.func,
    handleClickProposalItem: PropTypes.func,
    initTemplate: PropTypes.func,
    isDisabled: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    prepareDocumentCreate: PropTypes.func,
    prepareDocumentDelete: PropTypes.func,
    prepareDocumentDuplicate: PropTypes.func,
    prepareDocumentRename: PropTypes.func,
    proposalDocumentDefault: PropTypes.object,
    proposalId: PropTypes.string,
    resetTemplate: PropTypes.func,
    selectedDocument: PropTypes.object,
    setEditionDisabled: PropTypes.func,
    setSelectedDocument: PropTypes.func,
    smartDocuments: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
