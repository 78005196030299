import React, { useContext, useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import { Modal } from 'common/components';
import commentArea from 'common/modules/commentArea';
import fileUploader from 'common/modules/fileUploader';
import rejectForm from 'common/modules/rejectForm';
import { LoadingContext } from 'common/utils/contexts';

const FileReview = ({
    chatId,
    documentType,
    handleOnChangeStatusFile,
    handleOnRejectFile,
    onChangeFileStatus,
    onHide,
    selectedFile,
    show,
    showChat,
    uploadDocumentFile,
    user,
    userType,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [showModal, setShowModal] = useState(false);

    const handleOnClickReject = () => setShowModal(true);

    const handleOnClickChangeStatus = (status) => {
        loadingContext.openLoading(t('Saving'));
        handleOnChangeStatusFile(selectedFile, status, () => {
            loadingContext.closeLoading();
            onHide();
            onChangeFileStatus();
        });
    };

    const handleOnClickRejectFile = (selectedFile, callback) => {
        loadingContext.openLoading(t('Saving'));
        handleOnRejectFile(selectedFile, () => {
            loadingContext.closeLoading();
            callback();
            onHide();
            onChangeFileStatus();
        });
    };

    const handleSend = (tempFile, onSuccess) => {
        if (get(selectedFile, 'id', null) !== null)
            uploadDocumentFile(selectedFile, tempFile, onSuccess);
    };

    const showRejectModal = () => {
        if (!showModal) return null;
        return (
            <rejectForm.Container
                callback={onHide}
                handleOnReject={handleOnClickRejectFile}
                setShowModal={setShowModal}
                chatId={chatId}
                selectedFile={selectedFile}
                documentId={selectedFile.id}
                documentType={documentType}
                user={user}
                onChangeFileStatus={onChangeFileStatus}
            />
        );
    };

    return (
        <Modal
            onHide={onHide}
            onExited={() => setShowModal(false)}
            size="lg"
            show={show}
            title=""
        >
            <Grid container style={{ height: '680px' }}>
                <Grid item xs sx={{ position: 'relative' }}>
                    <fileUploader.Container
                        handleSend={handleSend}
                        initialFile={selectedFile.file}
                        onClickChangeStatus={handleOnClickChangeStatus}
                        onClickReject={handleOnClickReject}
                        status={selectedFile.status}
                    />
                    {showRejectModal()}
                </Grid>
                {showChat && (
                    <Grid
                        item
                        xs
                        sx={{
                            display: 'flex',
                            height: '100%',
                            position: 'relative',
                        }}
                    >
                        <commentArea.Container
                            description={selectedFile.description}
                            documentId={selectedFile.id}
                            documentType={documentType}
                            title={selectedFile.name}
                            user={user}
                            userType={userType}
                        />
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
};

FileReview.propTypes = {
    chatId: PropTypes.string,
    documentType: PropTypes.string,
    handleOnChangeStatusFile: PropTypes.func,
    handleOnRejectFile: PropTypes.func,
    onChangeFileStatus: PropTypes.func,
    onHide: PropTypes.func,
    selectedFile: PropTypes.object,
    show: PropTypes.bool,
    showChat: PropTypes.bool,
    uploadDocumentFile: PropTypes.func,
    user: PropTypes.object,
    userType: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
    chatId: commentArea.selectors.getChatId,
});

export default connect(mapStateToProps, null)(FileReview);
