import { getItems } from 'common/api/rates';

import {
    FETCH_RATES,
    FETCH_RATES_FAILURE,
    FETCH_RATES_SUCCESS,
} from '../actionTypes';
import { proposalsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(proposalsActions[FETCH_RATES]());

    getItems()
        .then((response) => {
            dispatch(proposalsActions[FETCH_RATES_SUCCESS](response.data));
        })
        .catch((error) => {
            dispatch(proposalsActions[FETCH_RATES_FAILURE](error));
        });
};
