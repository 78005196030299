import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import updateProduct from './updateProduct';

export default (filterData, template) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Save'),
        defaultTextResponse: `${template.title}`,
        haveTextResponse: true,
        messages: [i18next.t('Name')],
        onSuccess: (title) =>
            dispatch(updateProduct(filterData, { ...template, title })),
        title: i18next.t('Change name'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'info',
    };

    dispatch(alerts.actions.show(dataAlert));
};
