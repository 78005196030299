import React from 'react';

import { useTranslation } from 'react-i18next';

import TabsSystem from 'common/components/TabsSystem';
import { PERMISSION_LIST } from 'common/constants/permissions';

import credits from '../credits';
import quotes from '../quotes';

const Container = () => {
    const { t } = useTranslation();

    const tabs = [
        {
            content: <credits.Container />,
            id: 'credits',
            label: t('Financing', { count: 2 }),
            requiredPermissions: [
                PERMISSION_LIST.ALLIANCE_DOCUMENTS_PERMISSION,
                PERMISSION_LIST.APPLICATION_DOCUMENTS_PERMISSION,
            ],
        },
        {
            content: <quotes.Container />,
            id: 'quotes',
            label: t('Quote', { count: 2 }),
            requiredPermissions: [
                PERMISSION_LIST.INSURANCE_DOCUMENTS_PERMISSION,
            ],
        },
    ];

    return (
        <TabsSystem
            baseUrl="applications"
            tabs={tabs}
            title={t('Financing requests')}
        />
    );
};

export default Container;
