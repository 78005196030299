import { updateTemplate } from 'common/api/templates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

export default (updatedData) => (dispatch) =>
    new Promise((resolve, reject) => {
        updateTemplate(
            {
                content: JSON.stringify(updatedData),
                page_size: updatedData.page_size,
            },
            updatedData.id
        )
            .then((response) => resolve(response.data))
            .catch((error) => {
                reject(error.response.data.errors);
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    });
