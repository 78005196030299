import { updateTemplateByRowEdition } from 'common/api/templates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { templatesActions } from '../reducer';

import fetchTemplates from './fetchTemplates';

export default (filterData, values) => (dispatch) => {
    if (!values.id) return;

    const newValues = { title: values.title };

    dispatch(templatesActions[SAVE_ITEM]());

    updateTemplateByRowEdition(values.id, newValues)
        .then(() => {
            dispatch(templatesActions[SAVE_ITEM_SUCCESS]());
            dispatch(fetchTemplates(filterData));
            showToast();
        })
        .catch((error) => {
            dispatch(
                templatesActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
