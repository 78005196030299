import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as templatesReducer } from 'sunwise-template-core';

import alerts from 'common/modules/alerts';
import chat from 'common/modules/chat';
import commentArea from 'common/modules/commentArea';
import customColumns from 'common/modules/customColumns';
import fileUploader from 'common/modules/fileUploader';
import privateDocuments from 'common/modules/privateDocuments';
import rejectForm from 'common/modules/rejectForm';

import companyGeneral from '../companyGeneral';
import companyRoles from '../companyRoles';
import companyUsers from '../companyUsers';
import configurationDocuments from '../configurationDocuments';
import configurationInsurances from '../configurationInsurances';
import creditFile from '../creditFile';
import credits from '../credits';
import dashboard from '../dashboard';
import editTemplateView from '../editTemplateView';
import financial from '../financial';
import financialBepensa from '../financialBepensa';
import financialBright from '../financialBright';
import financialBrove from '../financialBrove';
import financialCeide from '../financialCeide';
import financialCiBanco from '../financialCiBanco';
import financialGalt from '../financialGalt';
import financialRedGirasol from '../financialRedGirasol';
import financialSerfimex from '../financialSerfimex';
import funnelFinancing from '../funnelFinancing';
import installerProfile from '../installerProfile';
import installers from '../installers';
import login from '../login';
import notifications from '../notifications';
import profile from '../profile';
import profileConfiguration from '../profileConfiguration';
import profileSecurity from '../profileSecurity';
import proposalPreview from '../proposalPreview';
import quotes from '../quotes';
import resetPasswordUser from '../resetPasswordUser';
import templateDocuments from '../templateDocuments';
import templates from '../templates';

export default (history) =>
    combineReducers({
        [alerts.NAME]: alerts.reducer,
        [chat.NAME]: chat.reducer,
        [commentArea.NAME]: commentArea.reducer,
        [companyGeneral.NAME]: companyGeneral.reducer,
        [companyRoles.NAME]: companyRoles.reducer,
        [companyUsers.NAME]: companyUsers.reducer,
        [configurationDocuments.NAME]: configurationDocuments.reducer,
        [configurationInsurances.NAME]: configurationInsurances.reducer,
        [creditFile.NAME]: creditFile.reducer,
        [credits.NAME]: credits.reducer,
        [customColumns.NAME]: customColumns.reducer,
        [dashboard.NAME]: dashboard.reducer,
        [editTemplateView.NAME]: editTemplateView.reducer,
        [fileUploader.NAME]: fileUploader.reducer,
        [financial.NAME]: financial.reducer,
        [financialBepensa.NAME]: financialBepensa.reducer,
        [financialBright.NAME]: financialBright.reducer,
        [financialBrove.NAME]: financialBrove.reducer,
        [financialCeide.NAME]: financialCeide.reducer,
        [financialCiBanco.NAME]: financialCiBanco.reducer,
        [financialGalt.NAME]: financialGalt.reducer,
        [financialRedGirasol.NAME]: financialRedGirasol.reducer,
        [financialSerfimex.NAME]: financialSerfimex.reducer,
        [funnelFinancing.NAME]: funnelFinancing.reducer,
        [installerProfile.NAME]: installerProfile.reducer,
        [installers.NAME]: installers.reducer,
        [login.NAME]: login.reducer,
        [notifications.NAME]: notifications.reducer,
        [privateDocuments.NAME]: privateDocuments.reducer,
        [profile.NAME]: profile.reducer,
        [profileSecurity.NAME]: profileSecurity.reducer,
        [proposalPreview.NAME]: proposalPreview.reducer,
        [quotes.NAME]: quotes.reducer,
        [rejectForm.NAME]: rejectForm.reducer,
        [resetPasswordUser.NAME]: resetPasswordUser.reducer,
        [templateDocuments.NAME]: templateDocuments.reducer,
        [templates.NAME]: templates.reducer,
        [profileConfiguration.NAME]: profileConfiguration.reducer,
        form: formReducer,
        router: connectRouter(history),
        sunwiseTemplateCore: templatesReducer,
    });
