import React from 'react';

import i18next from 'i18next';

import TabsSystem from 'common/components/TabsSystem';
import { PERMISSION_LIST } from 'common/constants/permissions';

import companyGeneral from '../companyGeneral';
import companyRoles from '../companyRoles';
import companyUsers from '../companyUsers';

const getTabs = () => [
    {
        content: <companyGeneral.Container />,
        id: 'general',
        label: i18next.t('General'),
        requiredPermissions: [PERMISSION_LIST.GENERAL_INFORMATION_PERMISSION],
    },

    {
        content: <companyRoles.Container />,
        id: 'roles',
        label: i18next.t('Role', { count: 2 }),
        requiredPermissions: [PERMISSION_LIST.ROLES_PERMISSION],
    },

    {
        content: <companyUsers.Container />,
        id: 'users',
        label: i18next.t('User', { count: 2 }),
        requiredPermissions: [PERMISSION_LIST.USERS_PERMISSION],
    },
];

const Container = () => (
    <TabsSystem baseUrl="company" tabs={getTabs()} title="Empresa" />
);

export default Container;
