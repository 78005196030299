import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import ReorderIcon from '@mui/icons-material/Reorder';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import {
    Box,
    Divider,
    IconButton,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from 'sunwise-ui';

const SortableItem = ({
    canModify,
    companyCatalog,
    index,
    prepareUpdate,
    type,
}) => {
    return (
        <Draggable draggableId={companyCatalog.id} index={index}>
            {(provided) => (
                <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Box>
                        <ListItemButton>
                            <ListItemIcon>
                                <ReorderIcon fontSize="small" />
                            </ListItemIcon>

                            <ListItemText primary={companyCatalog.name} />

                            {canModify && (
                                <IconButton
                                    onClick={() =>
                                        prepareUpdate(companyCatalog, type)
                                    }
                                    sx={{ ml: 'auto' }}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            )}
                        </ListItemButton>
                        <Divider />
                    </Box>
                </Box>
            )}
        </Draggable>
    );
};

SortableItem.propTypes = {
    canModify: PropTypes.bool,
    companyCatalog: PropTypes.object,
    index: PropTypes.number,
    prepareUpdate: PropTypes.func,
    type: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

export default SortableItem;
