import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissions';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from './actions';
import DataForm from './components/DataForm';
import TermsForm from './components/TermsForm';
import * as selectors from './selectors';

const Container = ({
    canModify,
    fetchCompanyData,
    fetchTermsAndConditions,
    isFetchingCompanyData,
    reset,
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        fetchCompanyData();
        fetchTermsAndConditions();
        return () => reset();
    }, []);

    return (
        <>
            <HeaderContainer sxHeader={{ pt: 0 }}>
                <Grid item xs>
                    <TitleIcon
                        fontWeight="bold"
                        title={t('General information')}
                        variant="subtitle"
                    />
                </Grid>
            </HeaderContainer>

            <Grid container>
                <Grid item lg={9} xs={18}>
                    <DataForm
                        canModify={canModify}
                        isFetchingCompanyData={isFetchingCompanyData}
                    />
                </Grid>
                <Grid item lg={9} xs={18}>
                    <TermsForm />
                </Grid>
            </Grid>
        </>
    );
};

Container.propTypes = {
    canModify: PropTypes.bool,
    fetchCompanyData: PropTypes.func,
    fetchTermsAndConditions: PropTypes.func,
    isFetchingCompanyData: PropTypes.bool,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isFetchingCompanyData: selectors.getIsFetchingCompanyData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompanyData: () => dispatch(actions.fetchData()),
    fetchTermsAndConditions: () => dispatch(actions.fetchTermsAndConditions()),
    reset: () => dispatch(actions.reset()),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.GENERAL_INFORMATION_PERMISSION)
)(Container);
