import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

import { getFilteredGroupsIds, getGroupIem } from '../helpers';

import GroupItem from './GroupItem';

const ReduxFieldArray = ({
    category,
    disabled,
    fields,
    formValues,
    roleLevel,
}) => {
    const groups = get(formValues, 'groups', []);
    const groupsIds = getFilteredGroupsIds(category, groups);
    return fields.map((item, index) => {
        const group = getGroupIem(formValues, index);
        const newIndex = groupsIds.indexOf(group.id);
        if (group.category !== category) return null;
        return (
            <GroupItem
                disabled={disabled}
                index={newIndex}
                item={group}
                key={`group-${group.id}`}
                name={`${item}.permission_level`}
                roleLevel={roleLevel}
            />
        );
    });
};

ReduxFieldArray.propTypes = {
    category: PropTypes.string,
    disabled: PropTypes.bool,
    fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    formValues: PropTypes.object,
    roleLevel: PropTypes.object,
};

export default ReduxFieldArray;
