import API from '..';
const ENTITY = 'listed';

export const filterQuotes = ({
    company = '',
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    rateName = 'all',
    searchText,
    sortBy = null,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            sort: sortBy,
            ...(company ? { company } : {}),
            ...(rateName !== 'all' ? { rate: rateName } : {}),
            ...(searchText ? { search: searchText } : {}),
        },
    });

export const generatePreview = (data) =>
    API.post(`/api/v1/${ENTITY}/generate-preview/`, { ...data });
