import React, { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { Box, Divider, Grid, IconButton, Typography } from 'sunwise-ui';

import {
    ReduxFieldCheck,
    ReduxFieldInput,
    ReduxFieldSelect,
    ReduxToggleButtonGroupField,
} from 'common/components/form/redux';
import {
    DEFAULT_ALGORITHM_TYPES,
    DEFAULT_PRODUCT_TYPES,
    DEFAULT_RATES,
    DEFAULT_TERMS,
    DEFAULT_TYPES_BUSINESS,
} from 'common/constants';
import { mergeTermsData } from 'common/utils/helpers';

import CheckboxButtonField from '../../financial/components/productForm/CheckboxButtonField';
import RadioButtonField from '../../financial/components/productForm/RadioButtonField';

const Parameters = ({
    changeInput,
    coverages,
    disabled,
    fetchCoverages,
    fetchTemplates,
    formValues,
    templates,
}) => {
    const { t } = useTranslation();
    const [defaultTerms, setTerms] = useState(DEFAULT_TERMS);

    const terms = get(formValues, 'parameters.terms', []);

    useEffect(() => {
        fetchCoverages();
        fetchTemplates();
    }, []);

    useEffect(() => {
        if (terms.length > 0) {
            const object_keys = Object.keys(formValues.terms);
            let selectedTerms = [];

            object_keys.forEach((item) => {
                selectedTerms.push({
                    label: item,
                    value: item,
                });
            });

            const nonDuplidatedAndSortData = mergeTermsData(
                defaultTerms,
                selectedTerms
            );

            setTerms(nonDuplidatedAndSortData);
        }
    }, [terms]);

    const getCoverageOptions = () => {
        const options = coverages.map((item) => ({
            label: item.name,
            value: item.id,
        }));
        return [
            {
                disabled: true,
                label: t('Select a coverage'),
                value: '',
            },
            ...options,
        ];
    };

    const getTemplateOptions = () => {
        const options = templates.map((item) => ({
            label: item.title,
            value: item.id,
        }));
        return [
            {
                disabled: true,
                label: t('Select a template'),
                value: '',
            },
            ...options,
        ];
    };

    const getTermsOptions = () => {
        let data = [...DEFAULT_TERMS, { label: '156', value: '156' }];
        return sortBy(data, [
            function (o) {
                return parseInt(o.value);
            },
        ]);
    };

    return (
        <Box>
            <Field
                component={ReduxFieldInput}
                disabled={disabled}
                label={`${t('Product name')} *`}
                name="name"
                sx={{ mb: 2 }}
            />
            <Field
                as="textarea"
                component={ReduxFieldInput}
                defaultBehevior={true}
                disabled={disabled}
                label={t('General information about the product')}
                name="description"
                sx={{ mb: 2 }}
            />
            <Typography fontWeight="bold" variant="body2">
                {t('Template')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Field
                component={ReduxFieldSelect}
                disabled={disabled}
                name="template"
                options={getTemplateOptions()}
                sxBox={{ mb: 2 }}
            />
            <Typography fontWeight="bold" variant="body2">
                {t('Product type')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Field
                component={RadioButtonField}
                disabled={disabled}
                items={DEFAULT_PRODUCT_TYPES}
                name="type"
                prefixId="type"
            />
            <Typography fontWeight="bold" variant="body2">
                {t('Type of tax regime')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Field
                component={RadioButtonField}
                disabled={disabled}
                items={DEFAULT_TYPES_BUSINESS}
                name="types_business_company"
                prefixId="fiscal2"
            />
            <Typography fontWeight="bold" variant="body2">
                {t('Algorithm for run')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Field
                component={RadioButtonField}
                disabled={disabled}
                items={[
                    ...DEFAULT_ALGORITHM_TYPES,
                    {
                        label: t('Leasing with Solver'),
                        value: 'solver_payment',
                    },
                ]}
                name="type_algorithm"
                prefixId="type_algorithm"
            />
            <Typography fontWeight="bold" variant="body2">
                {t('Select rates to consider')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Field
                component={CheckboxButtonField}
                disabled={disabled}
                items={DEFAULT_RATES}
                name="rate_list"
                prefixId="rates"
            />
            <Typography fontWeight="bold" variant="body2">
                {t('Select product periods')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Field
                component={CheckboxButtonField}
                disabled={disabled}
                items={getTermsOptions()}
                name="parameters.terms"
                prefixId="terms"
            />
            <Typography fontWeight="bold" variant="body2">
                {t('Additional coverage')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Box display="flex" gap={2} mb={2}>
                <Field
                    component={ReduxFieldSelect}
                    disabled={disabled}
                    label=""
                    name="insurance_product"
                    options={getCoverageOptions()}
                />
                {formValues?.insurance_product && (
                    <IconButton
                        onClick={() => changeInput('insurance_product', null)}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                )}
            </Box>
            <Typography fontWeight="bold" variant="body2">
                {t('Dealer fee')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Field
                append="%"
                component={ReduxFieldInput}
                defaultBehevior={true}
                disabled={disabled}
                label="Dealer fee"
                name="dealer_fee"
                placeholder="%"
                sx={{ mb: 2 }}
                type="number"
            />
            <Typography fontWeight="bold" variant="body2">
                {t('Configure your product')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Grid container>
                <Grid item xs>
                    <Field
                        append="%"
                        component={ReduxFieldInput}
                        disabled={disabled}
                        label={t('Interest rate')}
                        name="parameters_rate.value"
                        sx={{ mb: 2 }}
                        type="number"
                    />
                </Grid>

                <Grid item xs>
                    <Field
                        append="%"
                        component={ReduxFieldInput}
                        defaultBehevior={true}
                        disabled={disabled}
                        label={t('VAT on interest')}
                        name="parameters.tax"
                        placeholder="%"
                        sx={{ mb: 2 }}
                        type="number"
                    />
                </Grid>
            </Grid>
            <Box
                hidden={['static_payment'].includes(formValues.type_algorithm)}
            >
                <Typography fontWeight="bold" variant="body2">
                    {t('Annual increase')}
                </Typography>

                <Divider sx={{ my: 1 }} />

                <Field
                    append="%"
                    component={ReduxFieldInput}
                    defaultBehevior={true}
                    disabled={disabled}
                    label={t('Annual increase')}
                    name="annual_increase"
                    placeholder="%"
                    sx={{ mb: 2 }}
                    type="number"
                />
            </Box>
            <Typography fontWeight="bold" variant="body2">
                {t('Commission for opening')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Grid container>
                <Grid item xs={2} lg={1} textAlign="center">
                    <Field
                        component={ReduxFieldCheck}
                        disabled={disabled}
                        label=""
                        name="parameters_commission_credit.isChecked"
                        onChange={(e) => {
                            if (!e.target.checked)
                                changeInput(
                                    'parameters_commission_credit.value',
                                    0
                                );
                        }}
                        sx={{ mb: 2 }}
                    />
                </Grid>

                <Grid item xs lg={8}>
                    <Field
                        append={
                            formValues.parameters_commission_credit &&
                            formValues.parameters_commission_credit.unit === 0
                                ? '%'
                                : 'MXN'
                        }
                        component={ReduxFieldInput}
                        disabled={
                            !formValues['parameters_commission_credit'] ||
                            !formValues['parameters_commission_credit']
                                .isChecked ||
                            disabled
                        }
                        label=""
                        name="parameters_commission_credit.value"
                        prepend={
                            formValues.parameters_commission_credit &&
                            formValues.parameters_commission_credit.unit === 1
                                ? '$'
                                : null
                        }
                        sx={{ mb: 2 }}
                        type="number"
                    />
                </Grid>

                <Grid item xs={9} lg={3}>
                    <Field
                        component={ReduxToggleButtonGroupField}
                        disabled={
                            !formValues['parameters_commission_credit'] ||
                            !formValues['parameters_commission_credit']
                                .isChecked ||
                            disabled
                        }
                        items={[
                            { label: 'Porcentaje', value: 0 },
                            { label: 'Monto', value: 1 },
                        ]}
                        name="parameters_commission_credit.unit"
                        onChange={() => {
                            changeInput(
                                'parameters_commission_credit.value',
                                0
                            );
                        }}
                        sx={{ mb: 2 }}
                    />
                </Grid>

                <Grid item xs lg={6}>
                    <Field
                        append="%"
                        component={ReduxFieldInput}
                        defaultBehevior={true}
                        disabled={disabled}
                        label={t('Commission VAT')}
                        name="parameters_commission_credit.opening_commission_vat"
                        placeholder="%"
                        sx={{ mb: 2 }}
                        type="number"
                    />
                </Grid>
            </Grid>
            <Typography fontWeight="bold" variant="body2">
                {t('Minimum down payment')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Grid container>
                <Grid item xs={2} lg={1} textAlign="center">
                    <Field
                        component={ReduxFieldCheck}
                        disabled={disabled}
                        label=""
                        name="parameters_hitch.isChecked"
                        onChange={(e) => {
                            if (!e.target.checked)
                                changeInput('parameters_hitch.value', 0);
                        }}
                        sx={{ mb: 2 }}
                    />
                </Grid>

                <Grid item xs lg={8}>
                    <Field
                        append={
                            formValues.parameters_hitch &&
                            formValues.parameters_hitch.unit === 0
                                ? '%'
                                : 'MXN'
                        }
                        component={ReduxFieldInput}
                        disabled={
                            !formValues['parameters_hitch'] ||
                            !formValues['parameters_hitch'].isChecked ||
                            disabled
                        }
                        label=""
                        name="parameters_hitch.value"
                        prepend={
                            formValues.parameters_hitch &&
                            formValues.parameters_hitch.unit === 1
                                ? '$'
                                : null
                        }
                        sx={{ mb: 2 }}
                        type="number"
                    />
                </Grid>

                <Grid item xs={9} lg={9}>
                    <Field
                        component={ReduxToggleButtonGroupField}
                        disabled={
                            !formValues['parameters_hitch'] ||
                            !formValues['parameters_hitch'].isChecked ||
                            disabled
                        }
                        items={[
                            { label: 'Porcentaje', value: 0 },
                            { label: 'Monto', value: 1 },
                        ]}
                        name="parameters_hitch.unit"
                        onChange={() => {
                            changeInput('parameters_hitch.value', 0);
                        }}
                        sx={{ mb: 2 }}
                    />
                </Grid>
            </Grid>
            <Typography fontWeight="bold" variant="body2">
                {t('Amount limit')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Grid container>
                <Grid item xs={2} lg={1} textAlign="center">
                    <Field
                        component={ReduxFieldCheck}
                        disabled={disabled}
                        label=""
                        name="amount_values.isChecked"
                        onChange={(e) => {
                            if (!e.target.checked) {
                                changeInput('amount_values.min', 0);
                                changeInput('amount_values.max', '');
                            }
                        }}
                        sx={{ mb: 2 }}
                    />
                </Grid>

                <Grid item xs lg={8}>
                    <Field
                        append="MXN"
                        component={ReduxFieldInput}
                        disabled={
                            !formValues['amount_values'] ||
                            !formValues['amount_values'].isChecked ||
                            disabled
                        }
                        label={t('Minimum amount')}
                        name="amount_values.min"
                        prepend="$"
                        sx={{ mb: 2 }}
                        type="number"
                    />
                </Grid>

                <Grid item xs lg={9}>
                    <Field
                        append="MXN"
                        component={ReduxFieldInput}
                        disabled={
                            !formValues['amount_values'] ||
                            !formValues['amount_values'].isChecked ||
                            disabled
                        }
                        label={t('Maximum amount')}
                        name="amount_values.max"
                        prepend="$"
                        sx={{ mb: 2 }}
                        type="number"
                    />
                </Grid>
            </Grid>
            <Typography fontWeight="bold" variant="body2">
                {t('Power limit')}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Grid container>
                <Grid item xs={2} lg={1} textAlign="center">
                    <Field
                        component={ReduxFieldCheck}
                        disabled={disabled}
                        label=""
                        name="power_values.isChecked"
                        onChange={(e) => {
                            if (!e.target.checked) {
                                changeInput('power_values.min', 0);
                                changeInput('power_values.max', '');
                            }
                        }}
                        sx={{ mb: 2 }}
                    />
                </Grid>

                <Grid item xs lg={8}>
                    <Field
                        append="kW"
                        component={ReduxFieldInput}
                        disabled={
                            !formValues['power_values'] ||
                            !formValues['power_values'].isChecked ||
                            disabled
                        }
                        label={t('Minimum power')}
                        name="power_values.min"
                        sx={{ mb: 2 }}
                        type="number"
                    />
                </Grid>

                <Grid item xs lg={9}>
                    <Field
                        append="kW"
                        component={ReduxFieldInput}
                        disabled={
                            !formValues['power_values'] ||
                            !formValues['power_values'].isChecked ||
                            disabled
                        }
                        label={t('Maximum power')}
                        name="power_values.max"
                        sx={{ mb: 2 }}
                        type="number"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

Parameters.propTypes = {
    changeInput: PropTypes.func,
    coverages: PropTypes.array,
    disabled: PropTypes.bool,
    fetchCoverages: PropTypes.func,
    fetchTemplates: PropTypes.func,
    formValues: PropTypes.object,
    templates: PropTypes.array,
};

export default Parameters;
