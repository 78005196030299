import isNumber from 'lodash/isNumber';

import { downloadFinancierInstallerDocuments } from 'common/api/financierInstallers';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SET_IS_DOWNLOADING } from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default (id, type, fileName) => (dispatch) => {
    if (!id || !isNumber(type) || !fileName) return;

    dispatch(installerProfileActions[SET_IS_DOWNLOADING](true));

    downloadFinancierInstallerDocuments(id, type)
        .then((response) => {
            dispatch(installerProfileActions[SET_IS_DOWNLOADING](false));
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.zip`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch((error) => {
            dispatch(installerProfileActions[SET_IS_DOWNLOADING](false));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
