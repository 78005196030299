import { fetchMessages as fetchAllianceMessages } from 'common/api/chatAlliance';
import { fetchMessages as fetchCreditMessages } from 'common/api/chatCredit';
import * as chatSocket from 'common/sockets/chat';

import {
    FETCH_MESSAGES,
    FETCH_MESSAGES_FAILURE,
    FETCH_MESSAGES_SUCCESS,
} from '../actionTypes';
import { CREDIT_DOCUMENT_TYPE, ALLIANCE_DOCUMENT_TYPE } from '../constants';
import { chatActions } from '../reducer';
import * as selectors from '../selectors';

import initializeForm from './initializeForm';

export default (canSendMessage, itemId, type, user) => (dispatch, getState) => {
    const action =
        type === ALLIANCE_DOCUMENT_TYPE
            ? fetchAllianceMessages
            : fetchCreditMessages;
    const state = getState();
    const socketConnected = selectors.getSocketConnected(state);

    dispatch(chatActions[FETCH_MESSAGES]());

    action(itemId)
        .then((response) => {
            if (type === CREDIT_DOCUMENT_TYPE) {
                if (socketConnected) dispatch(chatSocket.disconnect());
                dispatch(chatSocket.connect(response.data.id));
            }
            dispatch(chatActions[FETCH_MESSAGES_SUCCESS](response.data));

            const values = {
                chatId: response.data.id,
                is_comment: canSendMessage ? 0 : 1,
                message: '',
                type: type,
                user: user,
            };

            dispatch(initializeForm(values));
        })
        .catch((error) =>
            dispatch(
                chatActions[FETCH_MESSAGES_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
