import React, { useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Card,
    Grid,
    Menu,
    MenuItem,
    Typography,
} from 'sunwise-ui';

import { URL_TYPES } from 'common/constants';
import { formatDate, parseDate, subDate } from 'common/utils/dates';
import { handleDownloadSunwiseFile, handleFileURL } from 'common/utils/helpers';

import Charts from './Charts';

const Wrapper = styled(Box)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-bottom: 1px solid;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 8px;
    padding: 8px 16px;
`
);

const getMonthLabel = (isBimonthly, item) => {
    const daysToSubstract = isBimonthly ? 30 : 15;
    return formatDate(
        subDate(parseDate(item.final_date, 'dd/MM/yyyy'), {
            days: Math.floor(daysToSubstract),
        }),
        'MMM yy'
    );
};

const CardConsumption = ({
    consumptionPdfs,
    handleDownloadCSV,
    handleDownloadLisaFile,
    items,
    project,
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const { customer_energy_consumption, rate_division, rate } = project;

    const isBimonthly = get(customer_energy_consumption, 'is_bimonthly', false);

    return (
        <Card>
            <Card.Header>
                <Grid container>
                    <Grid item xs={18}>
                        <Typography fontWeight="bold" variant="body2">
                            {t('Service data')}
                        </Typography>
                    </Grid>
                </Grid>
            </Card.Header>
            <Card.Body>
                <Grid container>
                    <Grid item xs={18} md={10}>
                        <Wrapper>
                            <Typography variant="body2">{t('Name')}</Typography>
                            <Typography variant="body2" fontWeight="bold">
                                {customer_energy_consumption?.holder}
                            </Typography>
                        </Wrapper>
                    </Grid>
                    <Grid item xs={18} md={5}>
                        <Wrapper>
                            <Typography variant="body2">
                                {t('Service number')}
                            </Typography>
                            <Typography variant="body2" fontWeight="bold">
                                {customer_energy_consumption?.service_number}
                            </Typography>
                        </Wrapper>
                    </Grid>
                    <Grid item xs={18} md={3}>
                        <Wrapper>
                            <Typography variant="body2">{t('Rate')}</Typography>
                            <Typography variant="body2" fontWeight="bold">
                                {rate}
                            </Typography>
                        </Wrapper>
                    </Grid>
                    {rate_division?.name && (
                        <Grid item xs={18}>
                            <Wrapper>
                                <Typography variant="body2">
                                    {t('Region')}
                                </Typography>
                                <Typography variant="body2" fontWeight="bold">
                                    {rate_division?.name}
                                </Typography>
                            </Wrapper>
                        </Grid>
                    )}
                </Grid>

                <Box my={2}>
                    <Typography variant="body2" fontWeight="bold">
                        {t('Consumption history')}
                    </Typography>
                </Box>

                <Charts items={items} />

                <Grid container>
                    <Grid item xs={18}>
                        <Box sx={{ width: '100%' }} textAlign="right">
                            <Button
                                color="secondary"
                                endIcon={<KeyboardArrowDownIcon />}
                                onClick={handleClick}
                                startIcon={<DownloadIcon />}
                                sx={{ width: { xs: '100%', md: 'auto' } }}
                                variant="outlined"
                            >
                                {t('Download', { count: 2 })}
                            </Button>

                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleDownloadCSV(
                                            customer_energy_consumption?.id
                                        );
                                        handleClose();
                                    }}
                                >
                                    {t('Download')} CSV
                                </MenuItem>
                                {consumptionPdfs.map((month, index) => {
                                    const label =
                                        'PDF - ' +
                                        getMonthLabel(isBimonthly, month);
                                    if (month.url_type === URL_TYPES.LISA)
                                        return (
                                            <MenuItem
                                                key={`monthsWithPdf-${index}`}
                                                onClick={() => {
                                                    handleDownloadLisaFile(
                                                        month.url
                                                    );
                                                    handleClose();
                                                }}
                                            >
                                                {label}
                                            </MenuItem>
                                        );
                                    return (
                                        <MenuItem
                                            key={`monthsWithPdf-${index}`}
                                            onClick={() => {
                                                const url = handleFileURL(
                                                    month.url,
                                                    process.env
                                                        .REACT_APP_API_URL
                                                );
                                                handleDownloadSunwiseFile(
                                                    url,
                                                    getMonthLabel(
                                                        isBimonthly,
                                                        month
                                                    )
                                                );
                                                handleClose();
                                            }}
                                        >
                                            {label}
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        </Box>
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

CardConsumption.propTypes = {
    consumptionPdfs: PropTypes.array,
    handleDownloadCSV: PropTypes.func,
    handleDownloadLisaFile: PropTypes.func,
    items: PropTypes.array,
    project: PropTypes.object,
};

export default CardConsumption;
