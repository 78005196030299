import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Card,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import { AutocompleteField, InputSearch } from 'common/components';

import { getRateOptions, getCompanyOptions } from '../helpers';

const BulkOperationsCard = ({
    companies,
    filterData,
    handleOnChangeSearch,
    handleOnSelectCompany,
    handleOnSelectRate,
    isFetching,
    rates,
}) => {
    const { t } = useTranslation();

    return (
        <Card sx={{ mb: '16px !important' }}>
            <Card.Body>
                <Grid alignItems="end" container justifyContent="end">
                    <Grid item md={9} lg={12} xs={18}>
                        <InputSearch
                            id="input-search"
                            onChange={handleOnChangeSearch}
                            placeholder={t('Search')}
                            value={filterData?.searchText}
                        />
                    </Grid>
                    <Grid item md={5} lg={4} xs={18}>
                        <AutocompleteField
                            disabled={isFetching}
                            label={
                                isEmpty(filterData?.company)
                                    ? t('All the companies')
                                    : t('Company')
                            }
                            onChange={handleOnSelectCompany}
                            options={getCompanyOptions(companies)}
                            value={filterData?.company}
                        />
                    </Grid>
                    <Grid item md={4} lg={2} xs={18}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Rate')}</InputLabel>
                            <Select
                                label={t('Rate')}
                                onChange={(e) => handleOnSelectRate(e.target)}
                                value={filterData.rateName}
                                variant="standard"
                            >
                                {getRateOptions(rates).map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`rate-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    companies: PropTypes.array,
    filterData: PropTypes.object,
    handleOnChangeSearch: PropTypes.func,
    handleOnSelectCompany: PropTypes.func,
    handleOnSelectRate: PropTypes.func,
    isFetching: PropTypes.bool,
    rates: PropTypes.array,
};

export default BulkOperationsCard;
