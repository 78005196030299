import { changeStatusFile } from 'common/api/creditApplications';
import { REJECTED_STATUS } from 'common/constants';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    REJECT_FILE,
    REJECT_FILE_FAILURE,
    REJECT_FILE_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

export default (file, callback) => (dispatch) => {
    const newValues = { status: REJECTED_STATUS };

    dispatch(creditFileActions[REJECT_FILE]());

    changeStatusFile(file.id, newValues)
        .then((response) => {
            dispatch(creditFileActions[REJECT_FILE_SUCCESS](response.data));
            if (callback) callback();
            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            dispatch(
                creditFileActions[REJECT_FILE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            if (callback) callback();
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
