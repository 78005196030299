import get from 'lodash/get';

import { getCompany } from 'common/api/companies';
import { updateSession } from 'common/utils/session';

import {
    FETCH_COMPANY,
    FETCH_COMPANY_SUCCESS,
    FETCH_COMPANY_FAILURE,
} from '../actionTypes';
import { companyGeneralActions } from '../reducer';

import initializeForm from './initializeForm';
import resetForm from './resetForm';

export default () => (dispatch) => {
    dispatch(companyGeneralActions[FETCH_COMPANY]());
    dispatch(resetForm());
    getCompany()
        .then((response) => {
            const data = get(response, 'data', {});
            const { chat_alliance, chat_documents, image } = data;
            let values = data;
            if (image) values.image = image;
            updateSession({
                chat_settings: {
                    chat_alliance,
                    chat_documents,
                },
            });
            dispatch(companyGeneralActions[FETCH_COMPANY_SUCCESS](data));
            dispatch(initializeForm(values));
        })
        .catch((error) =>
            dispatch(
                companyGeneralActions[FETCH_COMPANY_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
