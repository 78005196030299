import React, { useEffect } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Container as MaterialContainer, Divider, Grid } from 'sunwise-ui';

import { SlidesContainer } from 'common/components/socialSlide';
import { getIsGuest } from 'common/utils/session';

import divider from 'resources/diamondDivider.svg';
import fondoLogin1 from 'resources/fondoLogin1.png';
import logotipo from 'resources/logotipo.svg';
import imgSunpay from 'resources/slides/login/img_sunpay.png';

import { loginUser } from './actions';
import LoginForm from './components/LoginForm';
import * as selectors from './selectors';

const StyledGrid = styled(Grid)`
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background: #fff url(${fondoLogin1});
`;

const StyledSlideTitle = styled('h1')`
    font-size: 26px !important;
    line-height: 32px;
    margin-top: auto;
    text-align: left;
`;

const StyledSlideImage = styled('img')`
    cursor: pointer;
    display: block;
    margin: auto;
    width: 100%;
`;

const StyledSlideLabel = styled('span')`
    color: #ff9a00;
`;

const StyledSlideLink = styled('a')`
    color: #ff9a00 !important;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: normal !important;
    line-height: 26px !important;

    &:hover {
        color: #ff9a00 !important;
        text-decoration: none;
    }
`;

const ImageContentSlide = ({
    description,
    featuredDescripton,
    featuredTitle,
    imgUrl,
    link,
    title,
}) => (
    <>
        <StyledSlideTitle>
            {title}
            <StyledSlideLabel>{featuredTitle}</StyledSlideLabel>
        </StyledSlideTitle>

        <p>
            {description}&nbsp;
            <StyledSlideLink href={link} target="_blank">
                {featuredDescripton}
                <StyledSlideImage src={imgUrl} />
            </StyledSlideLink>
        </p>
    </>
);

ImageContentSlide.propTypes = {
    description: PropTypes.string,
    featuredDescripton: PropTypes.string,
    featuredTitle: PropTypes.string,
    imgUrl: PropTypes.string,
    link: PropTypes.string,
    title: PropTypes.string,
};

const Container = ({
    errors,
    initialValues,
    isLoadingSession,
    loginUser,
    redirect,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    useEffect(() => {
        if (!getIsGuest()) redirect('/applications');
    }, []);

    const handleSubmit = (values) => loginUser(values);

    const slides = [
        {
            content: (
                <ImageContentSlide
                    description=""
                    featuredDescripton=""
                    featuredTitle=""
                    imgUrl={imgSunpay}
                    link=""
                    title=""
                />
            ),
        },
    ];

    return (
        <MaterialContainer maxWidth={false} sx={{ p: '0 !important' }}>
            <Grid
                alignItems="center"
                container
                spacing={0}
                sx={{ display: 'flex', minHeight: 'calc(100vh - 43px)' }}
            >
                <StyledGrid
                    item
                    lg={9}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        minHeight: '100%',
                    }}
                    xs={18}
                >
                    <Box p={4}>
                        <Box sx={{ textAlign: 'center' }}>
                            <img alt="Sunpay" src={logotipo} width="265" />

                            <Box sx={{ margin: '3rem 0' }}>
                                <h1>{t('Log in')}</h1>
                            </Box>
                        </Box>

                        <Divider sx={{ margin: '3rem 0' }} />

                        <LoginForm
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            isLoadingSession={isLoadingSession}
                            errors={errors}
                        />
                    </Box>
                </StyledGrid>

                <Grid
                    item
                    lg={9}
                    sx={{
                        alignItems: 'center',
                        backgroundColor: isDarkMode
                            ? 'rgb(0, 0, 0, 0.6)'
                            : '#f6f8fa',
                        display: { lg: 'flex', xs: 'none' },
                        flexDirection: 'column',
                        justifyContent: 'center',
                        minHeight: '100%',
                        p: 4,
                    }}
                >
                    <Box sx={{ my: 2, textAlign: 'center' }}>
                        <img alt="divider" src={divider} />
                    </Box>

                    <SlidesContainer slides={slides} />
                </Grid>
            </Grid>
        </MaterialContainer>
    );
};

Container.propTypes = {
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isLoadingSession: PropTypes.bool,
    loginUser: PropTypes.func,
    redirect: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsLogin,
    initialValues: selectors.getInitialValues,
    isLoadingSession: selectors.getIsLoadingSession,
    validPassword: selectors.getValidPassword,
});

const mapDispatchToProps = (dispatch) => ({
    loginUser: (data) => dispatch(loginUser(data)),
    redirect: (link) => dispatch(push(link)),
});

Container.propTypes = {
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isLoadingSession: PropTypes.bool,
    loginUser: PropTypes.func,
    redirect: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
