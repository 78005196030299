import React from 'react';

import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button } from 'sunwise-ui';

import notFound from 'resources/notFound.svg';

const Wrapper = styled('div')`
    background: #011e2e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 30px;
    width: 100%;
`;

const Content = styled('div')`
    margin: 0 auto;
    width: 450px;

    h1 {
        color: #32347a;
        font-size: 116px;
        font-weight: 900;
        line-height: 141px;
    }

    p {
        color: #ffffff;
        font-size: 32px;
        line-height: 39px;
    }
`;

const BlankLayout = ({ redirect }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Content>
                <h1>
                    <img alt="" src={notFound} width="93" /> Upss
                </h1>

                <p>{t('Page not found')}</p>

                <Button
                    onClick={() => redirect('/applications')}
                    type="button"
                    variant="outlined"
                >
                    {t('Back to top"')}
                </Button>
            </Content>
        </Wrapper>
    );
};

const mapDispatchToProps = (dispatch) => ({
    redirect: (link) => dispatch(push(link)),
});

BlankLayout.propTypes = { redirect: PropTypes.func };

export default connect(null, mapDispatchToProps)(BlankLayout);
