import React from 'react';

import i18next from 'i18next';
import { Typography } from 'sunwise-ui';

const ProductColumns = () => {
    return [
        {
            align: 'left',
            field: 'name',
            flex: 1,
            headerName: i18next.t('Name'),
            renderCell: (params) => {
                const { row } = params;
                return <Typography variant="caption">{row.name}</Typography>;
            },
        },
    ];
};

ProductColumns.propTypes = {};

export default ProductColumns;
