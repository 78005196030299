import React, { useState, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Button,
    Container as MaterialContainer,
    UiDialog as Dialog,
    Grid,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';

import * as actions from './actions';
import ListItems from './components/ListItems';
import TemplateForm from './components/TemplateForm';
import * as selectors from './selectors';

const Container = ({
    fetchTemplates,
    handleClickBulkItems,
    handleClickDuplicate,
    handleClickRename,
    handleCloseModal,
    handleSaveRow,
    isFetching,
    isFetchingBulk,
    isOpenModal,
    paginationData,
    prepareCreate,
    prepareUpdate,
    templates,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        sortBy: 'desc',
        status: 'active',
    });
    const [currentFilterData, setCurrentFilterData] = useState({});

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            setCurrentFilterData(filterData);
            fetchTemplates(filterData);
        }
    }, [filterData]);

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <TitleIcon title={t('Template', { count: 2 })} />
            </HeaderContainer>

            <Grid container mb={2}>
                <Grid item xs={18} sx={{ textAlign: 'right' }}>
                    <Button
                        endIcon={<AddIcon />}
                        onClick={() => prepareCreate()}
                        sx={{ width: { md: 'auto', xs: '100%' } }}
                        variant="outlined"
                    >
                        {t('Add Template')}
                    </Button>
                </Grid>
            </Grid>

            <ListItems
                canDelete={true}
                canUpdate={true}
                filterData={filterData}
                filterItems={fetchTemplates}
                handleClickBulkItems={handleClickBulkItems}
                handleClickDuplicate={handleClickDuplicate}
                handleClickRename={handleClickRename}
                handleClickUpdate={prepareUpdate}
                handleSaveRow={handleSaveRow}
                isFetching={isFetching || isFetchingBulk}
                items={templates}
                paginationData={paginationData}
                setFilterData={setFilterData}
            />

            <Dialog
                onClose={handleCloseModal}
                open={isOpenModal}
                size="sm"
                title={t('Create template')}
            >
                <TemplateForm filterData={filterData} />
            </Dialog>
        </MaterialContainer>
    );
};

Container.propTypes = {
    fetchTemplates: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickRename: PropTypes.func,
    handleCloseModal: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingBulk: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    paginationData: PropTypes.object,
    prepareCreate: PropTypes.func,
    prepareUpdate: PropTypes.func,
    templates: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getIsFetchingTemplates,
    isFetchingBulk: selectors.getIsFetchingBulk,
    isOpenModal: selectors.getIsOpenModal,
    paginationData: selectors.getTemplatesPagination,
    templates: selectors.getTemplatesData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTemplates: (filterData) =>
        dispatch(actions.fetchTemplates(filterData)),
    handleClickBulkItems: (ids, value, filterData, callback) =>
        dispatch(actions.prepareBulkItems(ids, value, filterData, callback)),
    handleClickDuplicate: (product) =>
        dispatch(actions.prepareDuplicate(product)),
    handleClickRename: (filterData, product) =>
        dispatch(actions.prepareRename(filterData, product)),
    handleCloseModal: () => dispatch(actions.closeModal()),
    handleSaveRow: (filterData, item) =>
        dispatch(actions.updateProduct(filterData, item)),
    prepareCreate: () => dispatch(actions.prepareCreate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
