import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getFetchQuotes = createSelector(
    getModel,
    (model) => model.fetchQuotes
);

export const getIsFetchingQuotes = createSelector(
    getFetchQuotes,
    (model) => model.isFetching
);

export const getQuotesData = createSelector(
    getFetchQuotes,
    (model) => model?.data?.data || []
);

export const getQuotesPagination = createSelector(
    getFetchQuotes,
    (model) => model?.data?.pagination
);

export const getRates = createSelector(getModel, (model) => model.fetchRates);

export const getRatesData = createSelector(
    getRates,
    (model) => model.data || []
);

export const getIsFetchingRates = createSelector(
    getRates,
    (model) => model.isFetching
);

export const getRatesErrors = createSelector(getRates, (model) => model.errors);
