import { updateTemplate } from 'common/api/templates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
} from '../actionTypes';
import { editTemplateViewActions } from '../reducer';

export default (templateId, data) => (dispatch) =>
    new Promise((resolve, reject) => {
        if (!templateId) return;

        dispatch(editTemplateViewActions[UPDATE_TEMPLATE]());

        updateTemplate(
            { content: JSON.stringify(data), page_size: data.page_size },
            templateId
        )
            .then((response) => {
                dispatch(editTemplateViewActions[UPDATE_TEMPLATE_SUCCESS]());
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(
                    editTemplateViewActions[UPDATE_TEMPLATE_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                reject();
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    });
