import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_COMPANY,
    FETCH_COMPANY_FAILURE,
    FETCH_COMPANY_SUCCESS,
    FETCH_TERMS_AND_CONDITIONS,
    FETCH_TERMS_AND_CONDITIONS_FAILURE,
    FETCH_TERMS_AND_CONDITIONS_SUCCESS,
    INITIALIZE_FORM,
    INITIALIZE_FORM_TERMS,
    RESET,
    RESET_FORM,
    RESET_TERMS_FORM,
    SAVE_COMPANY,
    SAVE_COMPANY_FAILURE,
    SAVE_COMPANY_SUCCESS,
    SAVE_TERMS_AND_CONDITIONS,
    SAVE_TERMS_AND_CONDITIONS_FAILURE,
    SAVE_TERMS_AND_CONDITIONS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetchCompany: { data: {}, errors: [], isFetching: false },
    fetchTermsAndConditions: { data: {}, errors: [], isFetching: false },
    initialValues: { description: '', id: null, image: null, name: '' },
    initialValuesTerms: { content: '<h1>Hello</h1>', id: null },
    saveCompany: { errors: [], isSaving: false },
    saveTerms: { errors: [], isSaving: false },
};

const companyGeneralSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_COMPANY]: (state) => {
            state.fetchCompany.isFetching = true;
        },
        [FETCH_COMPANY_FAILURE]: (state, action) => {
            state.fetchCompany = {
                ...state.fetchCompany,
                isFetching: false,
                errors: action.payload,
            };
        },
        [FETCH_COMPANY_SUCCESS]: (state, action) => {
            state.fetchCompany = {
                ...state.fetchCompany,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TERMS_AND_CONDITIONS]: (state) => {
            state.fetchTermsAndConditions.isFetching = true;
        },
        [FETCH_TERMS_AND_CONDITIONS_FAILURE]: (state, action) => {
            state.fetchTermsAndConditions = {
                ...state.fetchTermsAndConditions,
                isFetching: false,
                errors: action.payload,
            };
        },
        [FETCH_TERMS_AND_CONDITIONS_SUCCESS]: (state) => {
            state.fetchTermsAndConditions.isFetching = false;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [INITIALIZE_FORM_TERMS]: (state, action) => {
            state.initialValuesTerms = {
                ...state.initialValuesTerms,
                ...action.payload,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.saveCompany.errors = [];
        },
        [RESET_TERMS_FORM]: (state) => {
            state.initialValuesTerms = INITIAL_STATE.initialValuesTerms;
            state.saveCompany.errors = [];
        },
        [SAVE_COMPANY]: (state) => {
            state.saveCompany.isSaving = true;
        },
        [SAVE_COMPANY_FAILURE]: (state, action) => {
            state.saveCompany = {
                ...state.saveCompany,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_COMPANY_SUCCESS]: (state) => {
            state.saveCompany.isSaving = false;
        },
        [SAVE_TERMS_AND_CONDITIONS]: (state) => {
            state.saveTerms.isSaving = true;
        },
        [SAVE_TERMS_AND_CONDITIONS_FAILURE]: (state, action) => {
            state.saveTerms = {
                ...state.saveTerms,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_TERMS_AND_CONDITIONS_SUCCESS]: (state) => {
            state.saveTerms.isSaving = false;
        },
    },
});

export const companyGeneralActions = companyGeneralSlice.actions;

export default companyGeneralSlice.reducer;
