import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import duplicateTemplate from './duplicateTemplate';

export default (template) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Duplicate'),
        defaultTextResponse: `${i18next.t('Copy of')}  ${template.title}`,
        haveTextResponse: true,
        messages: [i18next.t('Are you sure you want to duplicate this item?')],
        onSuccess: (title) =>
            dispatch(duplicateTemplate(template.id, { title })),
        title: i18next.t('Duplicate'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'info',
    };

    dispatch(alerts.actions.show(dataAlert));
};
