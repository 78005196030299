import React, { useContext, useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Alert,
    Box,
    Button,
    Card,
    Grid,
    IconButton,
    Typography,
} from 'sunwise-ui';

import {
    CompanyInformationCard,
    DocumentCard,
    LocationCard,
    Modal,
    StepperTimeline,
} from 'common/components';
import {
    APPROVED_STATUS,
    CANCELLED_STATUS,
    CLOSED_STATUS,
    CONTRACT_TYPE,
    INCOMPLETE_STATUS,
    PROCESS_STATUS,
    REJECTED_STATUS,
    REQUEST_FORM_TYPE,
    REQUESTED_STATUS,
} from 'common/constants';
import { ALLIANCE_DOCUMENTS_CATEGORY, DOCUMENTS_TYPE } from 'common/constants';
import chat from 'common/modules/chat';
import commentArea from 'common/modules/commentArea';
import privateDocuments from 'common/modules/privateDocuments';
import { LoadingContext } from 'common/utils/contexts';
import { getChatsConfig } from 'common/utils/session';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';

import CardAssignUser from './CardAssignUser';
import CardTotalProposalIssued from './CardTotalProposalIssued';
import CardTotalProposalTable from './CardTotalProposalTable';
import CompanyUsers from './CompanyUsers';
import InstallerDataForm from './InstallerDataForm';
import RequestedProductsCard from './RequestedProductsCard';

const Content = ({
    allianceId,
    assignedUser,
    canModify,
    contractDocuments,
    contractDocumentsList,
    documents,
    documentsList,
    downloadFiles,
    fetchContractDocuments,
    fetchDocuments,
    fetchFinancierInstaller,
    fetchInstaller,
    fetchProductsList,
    fetchRequestFormDocuments,
    fetchRequiredDocumentsList,
    fetchSelectedProducts,
    financierInstaller = {},
    handleChangeProductStatus,
    handleOnChangeStatusFile,
    handleOnRejectFile,
    installer,
    isDeletingDocument,
    isFetchingContractDocuments,
    isFetchingDocuments,
    isFetchingRequestFormDocuments,
    isSavingDocument,
    isUploadingFile,
    prepareApproveAlliance,
    prepareChangeStatus,
    prepareCloseAlliance,
    prepareDeleteDocumentFile,
    prepareRejectAlliance,
    prepareUpdateInstallerDataForm,
    productsList,
    proposalsTotals,
    proposalsTotalsIsLoading,
    requestFormDocuments,
    requestFormDocumentsList,
    reset,
    saveDocument,
    selectedProducts,
    uploadDocumentFile,
    uploadedFile,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const {
        business_name,
        city,
        image,
        legal_representative,
        location_latitude,
        location_longitude,
        name,
        phone,
        rfc,
        web_page,
    } = installer;

    const locationMarker = {
        name: city,
        position: {
            latitude: location_latitude,
            longitude: location_longitude,
        },
        title: name,
    };

    const installerId = financierInstaller.installer
        ? financierInstaller.installer.id
        : null;

    const financierId = financierInstaller.financier
        ? financierInstaller.financier.id
        : null;

    const [selectedFile, setSelectedFile] = useState({});
    const [statusModalUpdateInstallerData, setStatusModalUpdateInstallerData] =
        useState(false);

    const { chat_alliance } = getChatsConfig();

    useEffect(() => {
        if (allianceId) {
            fetchFinancierInstaller(allianceId);
            fetchSelectedProducts(allianceId);
        }

        return () => {
            reset();
        };
    }, [allianceId]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingDocument) {
            loadingContext.openLoading(t('Saving'));
        } else if (isUploadingFile) {
            loadingContext.openLoading(t('Uploading file'));
        } else {
            loadingContext.closeLoading();
        }
    }, [isSavingDocument, isUploadingFile]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isDeletingDocument)
            loadingContext.openLoading(t('Deleting document'));
        else loadingContext.closeLoading();
    }, [isDeletingDocument]);

    useEffect(() => {
        setSelectedFile(uploadedFile);
    }, [uploadedFile]);

    useEffect(() => {
        if (financierInstaller.installer) {
            fetchInstaller(financierInstaller.installer.id);
            fetchRequiredDocumentsList(financierInstaller.installer.id);
        }
    }, [financierInstaller.installer]);

    const handleOnClickApproval = () =>
        prepareApproveAlliance(allianceId, () =>
            fetchFinancierInstaller(allianceId)
        );

    const handleOnClickClose = () =>
        prepareCloseAlliance(allianceId, () =>
            fetchFinancierInstaller(allianceId)
        );

    const handleOnClickCancel = () =>
        prepareRejectAlliance(allianceId, () =>
            fetchFinancierInstaller(allianceId)
        );

    const handleSaveDocument = (values, callback) =>
        saveDocument({ ...values, installerId: installerId }, callback);

    const getRequiredDocumentsList = () =>
        fetchRequiredDocumentsList(installerId);

    const handleClickCloseModalUpdateInstallerData = () =>
        setStatusModalUpdateInstallerData(false);

    const handleClickOpenModalUpdateInstallerData = () =>
        setStatusModalUpdateInstallerData(true);

    const handleOnClickOpenUpdateInstallerModal = () => {
        prepareUpdateInstallerDataForm(installer);
        handleClickOpenModalUpdateInstallerData();
    };

    const handleOnSuccessUpdateInstallerData = () => {
        handleClickCloseModalUpdateInstallerData();
        fetchFinancierInstaller(allianceId);
    };

    const handleOnClickTimeLineItem = (item) => {
        prepareChangeStatus(
            { allianceId: allianceId, ...item },
            () => fetchFinancierInstaller(allianceId),
            loadingContext
        );
    };

    return (
        <React.Fragment>
            <Grid container>
                <Grid item lg={12} xs={18}>
                    <Box>
                        <CardAssignUser
                            allianceId={allianceId}
                            assignedUser={assignedUser}
                            canModify={canModify}
                            userTracking={financierInstaller.user_tracking}
                        />
                    </Box>

                    <Grid container>
                        <Grid item xs={18} md={7}>
                            <Box>
                                <CompanyInformationCard
                                    business_name={business_name}
                                    canModify={canModify}
                                    image={image}
                                    legal_representative={legal_representative}
                                    name={name}
                                    phone={phone}
                                    rfc={rfc}
                                    rightColumnComponent={
                                        <IconButton
                                            onClick={
                                                handleOnClickOpenUpdateInstallerModal
                                            }
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    }
                                    web_page={web_page}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={18} md={11}>
                            <Box>
                                <LocationCard
                                    address={t('Address')}
                                    location={locationMarker}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Card>
                            <Card.Header>
                                <Typography fontWeight="bold" variant="body2">
                                    {t('Chat')}
                                </Typography>
                            </Card.Header>

                            <Card.Body>
                                <chat.Container
                                    canSendMessage={chat_alliance}
                                    itemId={allianceId}
                                    key={`installer-chat-${allianceId}`}
                                    type="alliance"
                                    user={financierInstaller.financier}
                                />
                            </Card.Body>
                        </Card>
                    </Box>

                    <Box>
                        <privateDocuments.Container
                            canModify={canModify}
                            fetchId={installerId}
                            installer={financierInstaller.installer}
                            profileId={allianceId}
                            type={ALLIANCE_DOCUMENTS_CATEGORY}
                        />
                    </Box>

                    <Box>
                        <CompanyUsers installerId={installerId} />
                    </Box>

                    {!isNil(installer.id) && (
                        <Box>
                            <CardTotalProposalIssued
                                installerId={installer.id}
                                proposalsTotals={proposalsTotals}
                            />
                        </Box>
                    )}

                    {!proposalsTotalsIsLoading && (
                        <CardTotalProposalTable
                            proposalsTotals={proposalsTotals}
                        />
                    )}
                </Grid>

                <Grid item lg={6} xs={18}>
                    <Card>
                        <Card.Body>
                            <Typography
                                fontWeight="bold"
                                ml={2}
                                variant="body2"
                            >
                                {t('Registration request')}
                            </Typography>

                            {[CANCELLED_STATUS, REJECTED_STATUS].includes(
                                financierInstaller.status
                            ) && (
                                <Alert severity="error">
                                    {t(
                                        'The status of this request is "{{status}}"',
                                        { status: financierInstaller.status }
                                    )}
                                </Alert>
                            )}

                            <Box sx={{ py: 2, ml: '-1rem' }} mb={2}>
                                {/* 0, 8, 1, 3, 4 */}
                                <StepperTimeline
                                    canModify={canModify}
                                    isAlliance
                                    onClick={handleOnClickTimeLineItem}
                                    status={financierInstaller?.status}
                                />
                            </Box>

                            <RequestedProductsCard
                                allianceId={allianceId}
                                canModify={canModify}
                                fetchProductsList={() =>
                                    fetchProductsList(financierId, allianceId)
                                }
                                fetchSelectedProducts={() =>
                                    fetchSelectedProducts(allianceId)
                                }
                                financier={financierInstaller.financier}
                                handleChangeProductStatus={
                                    handleChangeProductStatus
                                }
                                productsList={productsList}
                                selectedProducts={selectedProducts}
                            />

                            <DocumentCard
                                canModify={canModify}
                                documents={requestFormDocuments}
                                documentType={
                                    commentArea.ALLIANCE_DOCUMENT_TYPE
                                }
                                fetchDocuments={fetchRequestFormDocuments}
                                fetchRequiredDocumentsList={
                                    getRequiredDocumentsList
                                }
                                handleOnChangeStatusFile={
                                    handleOnChangeStatusFile
                                }
                                handleOnClickDeleteFile={(document) =>
                                    prepareDeleteDocumentFile(
                                        document,
                                        installerId
                                    )
                                }
                                handleOnRejectFile={handleOnRejectFile}
                                isFetching={isFetchingRequestFormDocuments}
                                moduleName={NAME}
                                onChangeFileStatus={getRequiredDocumentsList}
                                onClickDownload={() =>
                                    downloadFiles(
                                        allianceId,
                                        REQUEST_FORM_TYPE,
                                        'Formatos_de_solicitud_de_alta'
                                    )
                                }
                                onSave={handleSaveDocument}
                                requiredDocuments={requestFormDocumentsList}
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                                showChat={chat_alliance}
                                title={t('Registration Request Form')}
                                uploadDocumentFile={uploadDocumentFile}
                                user={financierInstaller.financier}
                                userType={commentArea.FINANCIER_USER_TYPE}
                            />

                            <DocumentCard
                                canModify={canModify}
                                documents={documents}
                                documentType={
                                    commentArea.ALLIANCE_DOCUMENT_TYPE
                                }
                                fetchDocuments={fetchDocuments}
                                fetchRequiredDocumentsList={
                                    getRequiredDocumentsList
                                }
                                handleOnChangeStatusFile={
                                    handleOnChangeStatusFile
                                }
                                handleOnClickDeleteFile={(document) =>
                                    prepareDeleteDocumentFile(
                                        document,
                                        installerId
                                    )
                                }
                                handleOnRejectFile={handleOnRejectFile}
                                isFetching={isFetchingDocuments}
                                moduleName={NAME}
                                onChangeFileStatus={getRequiredDocumentsList}
                                onClickDownload={() =>
                                    downloadFiles(
                                        allianceId,
                                        DOCUMENTS_TYPE,
                                        'Documentos'
                                    )
                                }
                                onSave={handleSaveDocument}
                                requiredDocuments={documentsList}
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                                showChat={chat_alliance}
                                title={t('Document', { count: 2 })}
                                uploadDocumentFile={uploadDocumentFile}
                                user={financierInstaller.financier}
                                userType={commentArea.FINANCIER_USER_TYPE}
                            />

                            {[
                                INCOMPLETE_STATUS,
                                PROCESS_STATUS,
                                REQUESTED_STATUS,
                            ].includes(financierInstaller.status) &&
                                canModify && (
                                    <Button
                                        onClick={handleOnClickApproval}
                                        sx={{ width: '100%', mb: 1 }}
                                    >
                                        {t('Pre-approve Application')}
                                    </Button>
                                )}

                            {[APPROVED_STATUS, CLOSED_STATUS].includes(
                                financierInstaller.status
                            ) && (
                                <DocumentCard
                                    canModify={canModify}
                                    documents={contractDocuments}
                                    documentType={
                                        commentArea.ALLIANCE_DOCUMENT_TYPE
                                    }
                                    fetchDocuments={fetchContractDocuments}
                                    fetchRequiredDocumentsList={
                                        getRequiredDocumentsList
                                    }
                                    handleOnChangeStatusFile={
                                        handleOnChangeStatusFile
                                    }
                                    handleOnClickDeleteFile={(document) =>
                                        prepareDeleteDocumentFile(
                                            document,
                                            installerId
                                        )
                                    }
                                    handleOnRejectFile={handleOnRejectFile}
                                    isFetching={isFetchingContractDocuments}
                                    moduleName={NAME}
                                    onChangeFileStatus={
                                        getRequiredDocumentsList
                                    }
                                    onClickDownload={() =>
                                        downloadFiles(
                                            allianceId,
                                            CONTRACT_TYPE,
                                            'Contratos'
                                        )
                                    }
                                    onSave={handleSaveDocument}
                                    requiredDocuments={contractDocumentsList}
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    showChat={chat_alliance}
                                    title={t('Contract', { count: 2 })}
                                    uploadDocumentFile={uploadDocumentFile}
                                    user={financierInstaller.financier}
                                    userType={commentArea.FINANCIER_USER_TYPE}
                                />
                            )}

                            {financierInstaller.status === APPROVED_STATUS &&
                                canModify && (
                                    <Button
                                        color="info"
                                        onClick={handleOnClickClose}
                                        sx={{ width: '100%', mb: 1 }}
                                    >
                                        {t('Enable Alliance')}
                                    </Button>
                                )}

                            {[
                                APPROVED_STATUS,
                                INCOMPLETE_STATUS,
                                PROCESS_STATUS,
                                REQUESTED_STATUS,
                            ].includes(financierInstaller.status) &&
                                canModify && (
                                    <Button
                                        color="error"
                                        onClick={handleOnClickCancel}
                                        sx={{ width: '100%', mb: 1 }}
                                    >
                                        {t('Reject request')}
                                    </Button>
                                )}
                        </Card.Body>
                    </Card>
                </Grid>
            </Grid>

            <Modal
                onHide={handleClickCloseModalUpdateInstallerData}
                show={statusModalUpdateInstallerData}
                size="lg"
                title={t('Edit')}
            >
                <InstallerDataForm
                    installerId={installerId}
                    handleClickCloseForm={
                        handleClickCloseModalUpdateInstallerData
                    }
                    onSuccess={handleOnSuccessUpdateInstallerData}
                />
            </Modal>
        </React.Fragment>
    );
};

Content.propTypes = {
    allianceId: PropTypes.string,
    assignedUser: PropTypes.object,
    canModify: PropTypes.bool,
    contractDocuments: PropTypes.array,
    contractDocumentsList: PropTypes.array,
    documents: PropTypes.array,
    documentsList: PropTypes.array,
    downloadFiles: PropTypes.func,
    fetchContractDocuments: PropTypes.func,
    fetchDocuments: PropTypes.func,
    fetchFinancierInstaller: PropTypes.func,
    fetchInstaller: PropTypes.func,
    fetchProductsList: PropTypes.func,
    fetchRequestFormDocuments: PropTypes.func,
    fetchRequiredDocumentsList: PropTypes.func,
    fetchSelectedProducts: PropTypes.func,
    financierInstaller: PropTypes.object,
    handleChangeProductStatus: PropTypes.func,
    handleOnChangeStatusFile: PropTypes.func,
    handleOnRejectFile: PropTypes.func,
    installer: PropTypes.object,
    isDeletingDocument: PropTypes.bool,
    isFetchingContractDocuments: PropTypes.bool,
    isFetchingDocuments: PropTypes.bool,
    isFetchingRequestFormDocuments: PropTypes.bool,
    isSavingDocument: PropTypes.bool,
    isUploadingFile: PropTypes.bool,
    prepareApproveAlliance: PropTypes.func,
    prepareChangeStatus: PropTypes.func,
    prepareCloseAlliance: PropTypes.func,
    prepareDeleteDocumentFile: PropTypes.func,
    prepareRejectAlliance: PropTypes.func,
    prepareUpdateInstallerDataForm: PropTypes.func,
    productsList: PropTypes.array,
    proposalsTotals: PropTypes.object,
    proposalsTotalsIsLoading: PropTypes.bool,
    requestFormDocuments: PropTypes.array,
    requestFormDocumentsList: PropTypes.array,
    reset: PropTypes.func,
    saveDocument: PropTypes.func,
    selectedProducts: PropTypes.array,
    uploadDocumentFile: PropTypes.func,
    uploadedFile: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    assignedUser: selectors.getAssignedUserData,
    contacts: selectors.getContacts,
    contractDocuments: selectors.getContractDocumentsData,
    contractDocumentsList: selectors.getContractDocumentsList,
    documents: selectors.getDocumentsData,
    documentsList: selectors.getDocumentsList,
    financierInstaller: selectors.getFinancierInstallerData,
    installer: selectors.getDataFetchInstaller,
    isDeletingDocument: selectors.getIsDeletingDocument,
    isFetchingContractDocuments: selectors.getContractDocumentsIsFetching,
    isFetchingDocuments: selectors.getDocumentsIsFetching,
    isFetchingRequestFormDocuments: selectors.getRequestFormDocumentsIsFetching,
    isSavingDocument: selectors.getSaveDocumentIsSaving,
    isUploadingFile: selectors.isFileUploading,
    productsList: selectors.getProductsListData,
    proposalsTotals: selectors.getProposalsTotalsData,
    proposalsTotalsIsLoading: selectors.getProposalsTotalsIsLoading,
    requestFormDocuments: selectors.getRequestFormDocumentsData,
    requestFormDocumentsList: selectors.getRequestFormDocumentsList,
    selectedProducts: selectors.getSelectedProductsData,
    uploadedFile: selectors.getUploadedFileData,
});

const mapDispatchToProps = (dispatch) => ({
    downloadFiles: (id, type, fileName) =>
        dispatch(actions.downloadFiles(id, type, fileName)),
    fetchContractDocuments: () => dispatch(actions.fetchContractDocuments()),
    fetchDocuments: () => dispatch(actions.fetchDocuments()),
    fetchFinancierInstaller: (id) =>
        dispatch(actions.fetchFinancierInstaller(id)),
    fetchInstaller: (id) => dispatch(actions.fetchInstaller(id)),
    fetchProductsList: (financierId, allianceId) =>
        dispatch(actions.fetchProductsList(financierId, allianceId)),
    fetchRequestFormDocuments: () =>
        dispatch(actions.fetchRequestFormDocuments()),
    fetchRequiredDocumentsList: (id) =>
        dispatch(actions.fetchRequiredDocumentsList(id)),
    fetchSelectedProducts: (allianceId) =>
        dispatch(actions.fetchSelectedProducts(allianceId)),
    handleChangeProductStatus: (id, callback) =>
        dispatch(actions.handleChangeProductStatus(id, callback)),
    handleOnChangeStatusFile: (file, status, callback) =>
        dispatch(actions.changeStatusFile(file, status, callback)),
    handleOnRejectFile: (file, callback) =>
        dispatch(actions.rejectFile(file, callback)),
    prepareApproveAlliance: (id, callback) =>
        dispatch(actions.prepareApproveAlliance(id, callback)),
    prepareChangeStatus: (item, callback, loadingContext) =>
        dispatch(actions.prepareChangeStatus(item, callback, loadingContext)),
    prepareCloseAlliance: (id, callback) =>
        dispatch(actions.prepareCloseAlliance(id, callback)),
    prepareDeleteDocumentFile: (document, installerId) =>
        dispatch(actions.prepareDeleteDocumentFile(document, installerId)),
    prepareRejectAlliance: (id, callback) =>
        dispatch(actions.prepareRejectAlliance(id, callback)),
    prepareUpdateInstallerDataForm: (installer) =>
        dispatch(actions.prepareUpdateInstallerDataForm(installer)),
    reset: () => dispatch(actions.reset()),
    saveDocument: (values, callback) =>
        dispatch(actions.saveDocument(values, callback)),
    uploadDocumentFile: (document, file, onSuccess) =>
        dispatch(actions.uploadDocumentFile(document, file, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
