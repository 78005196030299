export const NAME = 'installers';
export const COLUMN_KEYS = {
    business_name: 'Business name',
    owner: 'Legal representative',
    user_tracking: 'Assigned executive',
    products: 'Product',
    phone: 'Phone',
    city: 'City',
    status: 'Status',
    created_at: 'Creation date',
};
export const REQUIRED_COLUMNS_KEYS = ['business_name', 'created_at'];
