import React, { useEffect, useRef, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import { styled, useTheme } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import {
    Avatar,
    Box,
    Button,
    FormControl,
    Grid,
    Paper,
    Typography,
} from 'sunwise-ui';

import { StyledH2 } from 'common/components';
import { ReduxToggleButtonGroupField } from 'common/components/form/redux';
import * as chatSocket from 'common/sockets/chat';
import { parseDate, formatDate } from 'common/utils/dates';
import { truncateString } from 'common/utils/helpers';
import { getSession, getToken } from 'common/utils/session';
import showToast from 'common/utils/showToast';

import * as userActions from '../../../modules/companyUsers/actions';
import * as userSelectors from '../../../modules/companyUsers/selectors';

import * as actions from './actions';
import { NAME, CREDIT_DOCUMENT_TYPE } from './constants';
import mentionsInputStyle from './mentionsInputStyle';
import mentionsMessageStyle from './mentionsMessageStyle';
import mentionStyle from './mentionStyle';
import * as selectors from './selectors';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    max-height: 500px;
    min-height: 300px;
`;

const Section = styled('div')`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
`;
const ScrollableContent = styled('div')`
    flex-grow: 1;
    margin-bottom: 15px;
    min-height: 0;
    overflow: auto;
`;

const Comment = styled('div')`
    border-radius: 3px;
    display: flex;
    gap: 1rem;
    margin-bottom: 5px;
    padding: 5px;

    ${({ variant }) =>
        variant === 'self' &&
        `flex-direction: row-reverse;

        .role-name {
            text-align: right;
        }`}
`;

const Content = styled('div')`
    width: 100%;
`;

const Message = styled('div')`
    font-size: 14px;
    ${({ variant }) =>
        variant === 'reject' &&
        `background-color: #f7edf0;

        .title{
            color: #FA6968;
            font-weight: 600;
            margin-bottom: 15px;
        }`}
`;

const MAX_LENGTH = 1000;

const Container = ({
    callback,
    canSendMessage,
    changeInput,
    disconnectSocket,
    fetchUsers,
    formValues,
    handleOnClickSend,
    handleSubmit,
    initChat,
    isFetching,
    itemId,
    messages,
    resetForm,
    sendMessage,
    socketConnected,
    socketError,
    type,
    updateMessage,
    user,
    users,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        if (
            itemId &&
            user &&
            user.id &&
            typeof user.id !== 'undefined' &&
            !isLoaded
        ) {
            initChat(canSendMessage, itemId, type, user);
            setIsLoaded(true);
        }
    }, [itemId, user]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if (socketError === null) return;
        showToast({
            body: socketError,
            type: 'danger',
            autoClose: 5000,
        });
    }, [socketError]);

    useEffect(() => {
        fetchUsers();
        return () => {
            if (type === CREDIT_DOCUMENT_TYPE && socketConnected)
                disconnectSocket();
            resetForm();
        };
    }, []);

    const handleOnClickSubmit = (values) => {
        if (type === CREDIT_DOCUMENT_TYPE && socketConnected) {
            const { chatId, is_comment, message, note_message } = values;
            const { id: userId } = getSession();
            const token = getToken();
            const toUserId = user.id;
            sendMessage(
                chatId,
                toUserId,
                message,
                note_message,
                token,
                userId,
                is_comment
            );
        } else {
            handleOnClickSend(values, callback);
        }
    };

    const messagesEndRef = useRef(null);

    const scrollToBottom = () =>
        messagesEndRef.current.scrollTo(0, messagesEndRef.current.scrollHeight);

    const disabled = !formValues.chatId;

    const renderDate = (comment) => {
        if (comment.created_at)
            return formatDate(
                parseDate(comment.created_at, 'dd/MM/yyyy HH:mm:ss'),
                'dd/MM/yyyy hh:mm:ss'
            );
        return get(comment, 'created_at', '');
    };

    const data = users.map((user) => ({
        display: `${user.first_name} ${user.last_name}`,
        id: user.id,
    }));

    return (
        <Wrapper>
            <Section>
                <ScrollableContent ref={messagesEndRef}>
                    {messages.map((comment) => (
                        <Paper
                            key={`comment-${comment.id}`}
                            sx={{
                                backgroundColor: comment.is_comment
                                    ? '#fdf6d9'
                                    : '',
                                color: comment.is_comment ? '#000' : '',
                            }}
                        >
                            <Comment>
                                <Avatar alt="" src={comment.image} />

                                <Content>
                                    <div className="role-name">
                                        <Grid container>
                                            <Grid item xs>
                                                <StyledH2 size="sm">
                                                    {comment.username}
                                                    &nbsp;-&nbsp;
                                                    <small>
                                                        ({comment.name}
                                                        &nbsp;-&nbsp;
                                                        {comment.role})
                                                    </small>
                                                </StyledH2>
                                            </Grid>

                                            <Grid item>
                                                <small>
                                                    {renderDate(comment)}
                                                </small>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <Message>
                                        <MentionsInput
                                            style={{
                                                ...mentionsMessageStyle,
                                                input: {
                                                    ...mentionsMessageStyle.input,
                                                    color: !comment.is_comment
                                                        ? isDarkMode
                                                            ? '#fff'
                                                            : '#000'
                                                        : '#000',
                                                },
                                            }}
                                            value={truncateString(
                                                comment.message,
                                                comment?.maxCharacters
                                            )}
                                        >
                                            <Mention
                                                data={data}
                                                style={
                                                    isDarkMode &&
                                                    !comment.is_comment
                                                        ? {
                                                              backgroundColor:
                                                                  'rgba(255, 255, 255, 0.09)',
                                                          }
                                                        : mentionStyle
                                                }
                                            />
                                        </MentionsInput>
                                        <Box
                                            hidden={
                                                comment?.message?.length <= 250
                                            }
                                            textAlign="center"
                                        >
                                            <Button
                                                color="secondary"
                                                variant="text"
                                                onClick={() =>
                                                    comment?.maxCharacters > 250
                                                        ? updateMessage({
                                                              commentId:
                                                                  comment.id,
                                                              maxCharacters: 250,
                                                          })
                                                        : updateMessage({
                                                              commentId:
                                                                  comment.id,
                                                              maxCharacters:
                                                                  comment
                                                                      ?.message
                                                                      ?.length,
                                                          })
                                                }
                                                sx={{ p: 0 }}
                                            >
                                                {comment?.maxCharacters >
                                                250 ? (
                                                    <ExpandLess
                                                        title={t('View less')}
                                                    />
                                                ) : (
                                                    <ExpandMore
                                                        title={t('View more')}
                                                    />
                                                )}
                                            </Button>
                                        </Box>
                                    </Message>
                                </Content>
                            </Comment>
                        </Paper>
                    ))}
                </ScrollableContent>

                <Field
                    component={ReduxToggleButtonGroupField}
                    disabled={disabled}
                    items={[
                        {
                            disabled: !canSendMessage,
                            label: t('Message'),
                            value: 0,
                        },
                        { label: t('Note'), value: 1 },
                    ]}
                    name="is_comment"
                />

                <form onSubmit={handleSubmit(handleOnClickSubmit)}>
                    <Box>
                        {formValues?.is_comment === 0 && canSendMessage ? (
                            <FormControl fullWidth>
                                <MentionsInput
                                    onChange={(e) =>
                                        changeInput('message', e.target.value)
                                    }
                                    placeholder={t('Write a message')}
                                    style={{
                                        ...mentionsInputStyle,
                                        control: {
                                            backgroundColor: '#fff',
                                            borderRadius: '8px',
                                            padding: '8.5px 14px',
                                        },
                                    }}
                                    value={formValues?.message}
                                >
                                    <Mention
                                        data={data}
                                        style={mentionStyle}
                                        trigger="@"
                                    />
                                </MentionsInput>
                            </FormControl>
                        ) : (
                            <FormControl fullWidth>
                                <MentionsInput
                                    onChange={(e) =>
                                        changeInput(
                                            'note_message',
                                            e.target.value
                                        )
                                    }
                                    placeholder="Escribe una nota"
                                    style={mentionsInputStyle}
                                    value={formValues?.note_message}
                                >
                                    <Mention
                                        data={data}
                                        style={mentionStyle}
                                        trigger="@"
                                    />
                                </MentionsInput>
                            </FormControl>
                        )}
                    </Box>
                    <Box
                        alignItems="end"
                        display="flex"
                        flexDirection="column"
                        gap={1}
                    >
                        <Typography variant="caption" mt={1}>
                            {get(formValues, 'message', '').length}/{MAX_LENGTH}
                        </Typography>
                        <Button
                            disabled={isFetching || disabled}
                            startIcon={<SendIcon />}
                            type="submit"
                            variant="outlined"
                        >
                            {t('Send')}
                        </Button>
                    </Box>
                </form>
            </Section>
        </Wrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isFetching: selectors.getSendIsFetching,
    messages: selectors.getMessages,
    socketConnected: selectors.getSocketConnected,
    socketError: selectors.getSocketError,
    users: userSelectors.getFetchItemsListData,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    disconnectSocket: () => dispatch(chatSocket.disconnect()),
    fetchUsers: () => dispatch(userActions.fetchItems()),
    fetchMessages: (values) => dispatch(actions.fetchMessages(values)),
    handleOnClickSend: (values, callback) =>
        dispatch(actions.sendMessage(values, callback)),
    initChat: (canSendMessage, itemId, type, user) =>
        dispatch(actions.initChat(canSendMessage, itemId, type, user)),
    resetForm: () => dispatch(actions.resetForm()),
    sendMessage: (
        chatId,
        toUserId,
        message,
        note_message,
        token,
        userId,
        isComment
    ) =>
        dispatch(
            chatSocket.sendMessage(
                chatId,
                toUserId,
                message,
                note_message,
                token,
                userId,
                isComment
            )
        ),
    updateMessage: (values) => dispatch(actions.updateMessage(values)),
});

Container.propTypes = {
    callback: PropTypes.func,
    canSendMessage: PropTypes.bool,
    changeInput: PropTypes.func,
    disconnectSocket: PropTypes.func,
    fetchUsers: PropTypes.func,
    formValues: PropTypes.object,
    handleOnClickSend: PropTypes.func,
    handleSubmit: PropTypes.func,
    initChat: PropTypes.func,
    isFetching: PropTypes.bool,
    itemId: PropTypes.string,
    messages: PropTypes.array,
    resetForm: PropTypes.func,
    sendMessage: PropTypes.func,
    socketConnected: PropTypes.bool,
    socketError: PropTypes.string,
    type: PropTypes.string,
    updateMessage: PropTypes.func,
    user: PropTypes.object,
    users: PropTypes.array,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
})(Container);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
