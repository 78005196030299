import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Card,
    Divider,
    Grid,
    List,
    ListItem,
    ToggleButton,
    ToggleButtonGroup,
} from 'sunwise-ui';

import { KeyValue } from 'common/components';
import { numberFormat } from 'common/utils/helpers';
import { getIsCiBanco } from 'common/utils/session';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
    ({ theme }) => `
    & .MuiToggleButton-secondary {
        color: ${theme.palette.mode === 'dark' ? '#fff' : '#002438'};
        padding: 6px 20px;

        &.Mui-selected {
            background-color: #002438;
            color: #fff;

            & :hover: {
                color: #002438;
            }
        }
    }
`
);

const RequestDetails = ({ handleOnShowModal, parameters }) => {
    const { t } = useTranslation();

    const [typeHitch, setTypeHitch] = useState('percentage');
    const handleChange = (event, newValue) => {
        setTypeHitch(newValue);
    };
    const isCibanco = getIsCiBanco();
    const {
        amount = 0,
        dealer_fee = 0,
        dealer_fee_amount = 0,
        hitch = 0,
        hitch_amount = 0,
        hitch_percentage = 0,
        loan_amount = 0,
        rate = 0,
        term = 12,
    } = parameters;

    return (
        <React.Fragment>
            <List>
                <ListItem>
                    <Grid container>
                        <Grid item md={isCibanco ? 9 : 6} xs={18}>
                            <KeyValue
                                append="mxn"
                                title={
                                    isCibanco ? t('Total') : t('System cost')
                                }
                                value={numberFormat(amount, {
                                    style: 'currency',
                                })}
                            />
                        </Grid>

                        <Grid hidden={!isCibanco} item md={9} xs={18}>
                            <KeyValue
                                append="mxn"
                                title={t('Total to finance')}
                                value={numberFormat(amount - hitch, {
                                    style: 'currency',
                                })}
                            />
                        </Grid>

                        <Grid hidden={isCibanco} item xs={18} md={6}>
                            <KeyValue
                                append="%"
                                title={`${t('Dealer fee')} %`}
                                value={numberFormat(dealer_fee, {
                                    style: 'decimal',
                                })}
                            />
                        </Grid>

                        <Grid hidden={isCibanco} item xs={18} md={6}>
                            <KeyValue
                                append="mxn"
                                title={t('Price with dealer fee')}
                                value={numberFormat(dealer_fee_amount, {
                                    style: 'currency',
                                })}
                            />
                        </Grid>
                    </Grid>
                </ListItem>

                <ListItem>
                    <Grid container>
                        <Grid item xs={18} md={6}>
                            {typeHitch === 'amount' && (
                                <KeyValue
                                    append="mxn"
                                    title={`${t('Hitch')} ($)`}
                                    value={numberFormat(
                                        isCibanco ? hitch : hitch_amount,
                                        { style: 'currency' }
                                    )}
                                />
                            )}
                            {typeHitch === 'percentage' && (
                                <KeyValue
                                    append="%"
                                    title={`${t('Hitch')} (%)`}
                                    value={numberFormat(hitch_percentage, {
                                        style: 'decimal',
                                    })}
                                />
                            )}
                        </Grid>

                        <Grid item xs={18} md={6}>
                            <StyledToggleButtonGroup
                                color="secondary"
                                exclusive
                                onChange={handleChange}
                                size="small"
                                value={typeHitch}
                            >
                                <ToggleButton
                                    value="percentage"
                                    sx={{ width: 30 }}
                                >
                                    %
                                </ToggleButton>
                                <ToggleButton value="amount" sx={{ width: 30 }}>
                                    $
                                </ToggleButton>
                            </StyledToggleButtonGroup>
                        </Grid>

                        <Grid hidden={isCibanco} item xs={18} md={6}>
                            <KeyValue
                                append="mxn"
                                title={t('Total to finance')}
                                value={numberFormat(loan_amount, {
                                    style: 'currency',
                                })}
                            />
                        </Grid>
                    </Grid>
                </ListItem>

                <ListItem>
                    <Grid container>
                        <Grid item xs={18} md={6}>
                            <KeyValue
                                append="%"
                                title={t('Rate', { context: 'financial' })}
                                value={rate}
                            />
                        </Grid>

                        <Grid item xs={18} md={6}>
                            <KeyValue
                                append={t('Month', { count: term })}
                                title={t('Term')}
                                value={term}
                            />
                        </Grid>
                    </Grid>
                </ListItem>
            </List>

            <Divider />

            <Card.Body sx={{ textAlign: 'center' }}>
                <Button
                    color="secondary"
                    onClick={handleOnShowModal}
                    variant="text"
                >
                    {t('See financial run')}
                </Button>
            </Card.Body>
        </React.Fragment>
    );
};

RequestDetails.propTypes = {
    handleOnShowModal: PropTypes.func,
    parameters: PropTypes.object,
};

export default RequestDetails;
