import i18next from 'i18next';

import { PERMISSION_LIST } from 'common/constants/permissions';
import { getIsCiBanco } from 'common/utils/session';

import { ROLE_LEVELS } from './constants';

export const getAllianceViewLevelOptions = () => {
    return [
        {
            disabled: true,
            label: i18next.t('Select a level'),
            value: '',
        },
        {
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: 0,
        },
        { label: i18next.t('Only those assigned'), value: 1 },
    ];
};

export const getFilteredGroupsIds = (category, groups) => {
    const filteredGroups = groups.filter((item) => item.category === category);
    return filteredGroups.map((item) => item.id);
};

export const getGroupIem = (formValues, index) => {
    return formValues?.groups?.[index] || {};
};

export const getIsDisabledFields = ({
    financierGroupType,
    isFetchingRoleLevels,
    isNewRecord,
    roleLevel,
}) => {
    const isSameLevel = financierGroupType.level === roleLevel?.level;
    const isSupLevel = financierGroupType.level > roleLevel?.level;
    if (isFetchingRoleLevels) return true;
    return (
        !isNewRecord &&
        (isSupLevel || isSameLevel || roleLevel?.level === ROLE_LEVELS.OWNER)
    );
};

export const getPermissionGroups = (categoriesSettings) => {
    const isCibanco = getIsCiBanco();
    return categoriesSettings.reduce((acc, current) => {
        if (current?.groups) {
            let currentGroups = current?.groups;
            if (isCibanco)
                currentGroups = current?.groups.filter(
                    (group) => group.id !== 4
                );
            currentGroups = currentGroups.map((item) => ({
                ...item,
                category: current?.id,
                category_name: current?.name,
            }));
            return [...acc, ...currentGroups];
        }
        return acc;
    }, []);
};

export const getPermissionLevelOptions = (roleLevel, permissionId) => {
    const options = [
        { label: i18next.t('View'), value: 'view' },
        { label: i18next.t('Edit'), value: 'modify' },
        {
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: 'all',
        },
    ];
    if (
        roleLevel?.level === ROLE_LEVELS.ADMIN ||
        permissionId ===
            PERMISSION_LIST.ALLIANCE_PRODUCT_APPLICATIONS_PERMISSION
    )
        return [
            {
                disabled: true,
                label: i18next.t('Select a permission'),
                value: '',
            },
            ...options,
        ];
    return [
        { disabled: true, label: i18next.t('Select a permission'), value: '' },
        { label: i18next.t('None'), value: 'none' },
        ...options,
    ];
};

export const getRoleCategories = (categoriesSettings) => {
    return categoriesSettings.map((item) => ({ id: item.id, name: item.name }));
};

export const getRoleLevelOptions = (levels) => {
    const options = levels
        .filter(({ level }) => level !== ROLE_LEVELS.OWNER)
        .map((item) => ({ label: item.name, value: item.id }));
    return [
        { disabled: true, label: i18next.t('Select a level'), value: '' },
        ...options,
    ];
};
