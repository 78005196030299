import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Box, Typography } from 'sunwise-ui';

import { ReduxFieldSelect } from 'common/components/form/redux';

import { getPermissionLevelOptions } from '../helpers';

import StyledItem from './StyledItem';

const GroupItem = ({ disabled, index, item, name, roleLevel }) => {
    return (
        <StyledItem className={index % 2 === 0 ? 'StripedRow' : ''}>
            <Typography id={item.id} variant="caption">
                {item?.name}
            </Typography>
            <Box ml="auto">
                <Field
                    component={ReduxFieldSelect}
                    disabled={disabled}
                    name={name}
                    options={getPermissionLevelOptions(roleLevel, item.id)}
                    sx={{ fontSize: '14px' }}
                    sxBox={{
                        '&.MuiFormControl-root': { mb: 0 },
                        '& .MuiInputBase-root': { mt: 0, fontSize: '14px' },
                    }}
                    variant="standard"
                />
            </Box>
        </StyledItem>
    );
};

GroupItem.propTypes = {
    disabled: PropTypes.bool,
    index: PropTypes.number,
    item: PropTypes.object,
    name: PropTypes.string,
    roleLevel: PropTypes.object,
};

export default GroupItem;
