import i18next from 'i18next';

import { createTemplate } from 'common/api/templates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { templatesActions } from '../reducer';

import closeModal from './closeModal';

export default (filterData, values) => (dispatch) => {
    const newValues = {
        title: values.name,
        language: values.language,
    };

    dispatch(templatesActions[SAVE_ITEM]());

    createTemplate(newValues, values.id)
        .then(() => {
            dispatch(templatesActions[SAVE_ITEM_SUCCESS]());
            dispatch(closeModal(filterData));
            showToast({ body: i18next.t('It was created successfully') });
        })
        .catch((error) => {
            dispatch(
                templatesActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
