export const MAX_CHARACTERS = 250;
export const NAME = 'quotes';
export const COLUMNS_CONFIG = {
    proposal_name: { label: 'Name', is_active: true, required: true },
    installer: { label: 'Company', is_active: false, required: false },
    proposal_created_by: {
        label: 'Created by',
        is_active: false,
        required: false,
    },
    amount: { label: 'Amount', is_active: true, required: false },
    type_business: {
        label: 'Type business',
        is_active: false,
        required: false,
    },
    project_name: { label: 'Project', is_active: true, required: false },
    finacier_product_name: {
        label: 'Product',
        is_active: true,
        required: false,
    },
    rate: { label: 'Fee', is_active: true, required: false },
    term: { label: 'Term', is_active: true, required: false },
    proposal_status: { label: 'Status', is_active: true, required: false },
    proposal_created_at: {
        label: 'Created at',
        is_active: true,
        required: false,
    },
    proposal_updated_at: {
        label: 'Updated at',
        is_active: true,
        required: false,
    },
};
