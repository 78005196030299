import React from 'react';

import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { getDecimalCount, numberFormat } from 'common/utils/helpers';

import NameFormatter from './NameFormatter';

const Columns = ({ hiddenColumns, orders }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const columnName = {
        field: 'proposal_name',
        headerName: t('Name'),
        hide: hiddenColumns.includes('proposal_name'),
        minWidth: 250,
        order: orders?.proposal_name,
        renderCell: NameFormatter,
    };

    let columns = [
        columnName,
        {
            field: 'installer',
            headerName: t('Company'),
            hide: hiddenColumns.includes('installer'),
            minWidth: 200,
            order: orders?.installer,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.installer?.name}
                    </Typography>
                );
            },
        },
        {
            field: 'proposal_created_by',
            headerName: t('Created by'),
            hide: hiddenColumns.includes('proposal_created_by'),
            minWidth: 200,
            order: orders?.proposal_created_by,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.proposal_created_by || '---'}
                    </Typography>
                );
            },
        },
        {
            field: 'amount',
            headerName: t('Amount'),
            hide: hiddenColumns.includes('amount'),
            minWidth: 120,
            order: orders?.amount,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {numberFormat(row?.amount, {
                            decimals: getDecimalCount(row?.amount),
                            style: 'currency',
                        })}
                    </Typography>
                );
            },
        },
        {
            field: 'type_business',
            headerName: t('Type business'),
            hide: hiddenColumns.includes('type_business'),
            minWidth: 150,
            order: orders?.type_business,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.type_business}
                    </Typography>
                );
            },
        },
        {
            field: 'project_name',
            headerName: t('Project'),
            hide: hiddenColumns.includes('project_name'),
            minWidth: 200,
            order: orders?.project_name,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.project_name}
                    </Typography>
                );
            },
        },
        {
            field: 'finacier_product_name',
            headerName: t('Product'),
            hide: hiddenColumns.includes('finacier_product_name'),
            minWidth: 200,
            order: orders?.finacier_product_name,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.finacier_product_name}
                    </Typography>
                );
            },
        },
        {
            field: 'rate',
            headerName: t('Fee'),
            hide: hiddenColumns.includes('rate'),
            minWidth: 80,
            order: orders?.rate,
            renderCell: (params) => {
                const { row } = params;
                return <Typography variant="caption">{row?.rate}%</Typography>;
            },
        },
        {
            field: 'term',
            headerName: t('Term'),
            hide: hiddenColumns.includes('term'),
            minWidth: 80,
            order: orders?.term,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.term} {t('Month', { count: 2 })}
                    </Typography>
                );
            },
        },
        {
            field: 'proposal_status',
            headerName: t('Status'),
            hide: hiddenColumns.includes('proposal_status'),
            minWidth: 100,
            order: orders?.proposal_status,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.proposal_status === 1
                            ? t('Generated')
                            : t('Draft')}
                    </Typography>
                );
            },
        },
        {
            field: 'proposal_created_at',
            headerName: t('Created at'),
            hide: hiddenColumns.includes('proposal_created_at'),
            minWidth: 120,
            order: orders?.proposal_created_at,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.proposal_created_at}
                    </Typography>
                );
            },
        },
        {
            field: 'proposal_updated_at',
            headerName: t('Updated at'),
            hide: hiddenColumns.includes('proposal_updated_at'),
            minWidth: 150,
            order: orders?.proposal_updated_at,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row.proposal_updated_at}
                    </Typography>
                );
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) columns = [columnName];

    return orderBy(columns, ['order'], ['asc']);
};

Columns.propTypes = {
    hiddenColumns: PropTypes.array,
    orders: PropTypes.object,
};

export default Columns;
