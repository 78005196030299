import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Card } from 'sunwise-ui';

import EmptyMessage from 'common/components/EmptyMessage';

import * as actions from '../actions';

import SortableList from './SortableList';

const CardListDetail = ({
    canModify,
    companiesCatalogs,
    handleClickCreate,
    isFetching,
    prepareDelete,
    prepareUpdate,
    type,
}) => {
    const { t } = useTranslation();

    const onDragEnd = (result) => {
        const { destination, source } = result;

        if (
            !destination ||
            (destination.droppableId === source.droppableId &&
                destination.index === source.index)
        )
            return;
    };

    const getButtonCreate = () => {
        return (
            <Button
                color="secondary"
                onClick={handleClickCreate}
                startIcon={<AddIcon fontSize="small" />}
                variant="outlined"
            >
                {t('Add')}
            </Button>
        );
    };

    if (isEmpty(type))
        return (
            <Card>
                <Card.Body>
                    <EmptyMessage text={t('Select a catalog to get started')} />
                </Card.Body>
            </Card>
        );

    if (isFetching)
        return (
            <Card>
                <Card.Body>
                    <EmptyMessage text={t('Loading')} />
                </Card.Body>
            </Card>
        );

    if (companiesCatalogs.length === 0)
        return (
            <Card>
                {canModify && (
                    <Card.Header sx={{ textAlign: 'right' }}>
                        {getButtonCreate()}
                    </Card.Header>
                )}
                <Card.Body>
                    <EmptyMessage text={t('There are no items to display')} />
                </Card.Body>
            </Card>
        );

    return (
        <Card>
            {canModify && (
                <Card.Header sx={{ textAlign: 'right' }}>
                    {getButtonCreate()}
                </Card.Header>
            )}
            <Card.Body>
                <SortableList
                    canModify={canModify}
                    companiesCatalogs={companiesCatalogs}
                    id={`list-${type}`}
                    onDragEnd={onDragEnd}
                    prepareDelete={prepareDelete}
                    prepareUpdate={prepareUpdate}
                    type={type}
                />
            </Card.Body>
        </Card>
    );
};

const mapDispatchToProps = (dispatch) => ({
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    prepareDelete: (id, type) => dispatch(actions.prepareDelete(id, type)),
    prepareUpdate: (companyCatalog) =>
        dispatch(actions.prepareUpdate(companyCatalog)),
    updateOrder: (oldIndex, newIndex) =>
        dispatch(actions.updateOrder(oldIndex, newIndex)),
});

CardListDetail.propTypes = {
    canModify: PropTypes.bool,
    companiesCatalogs: PropTypes.array,
    handleClickCreate: PropTypes.func,
    isFetching: PropTypes.bool,
    prepareDelete: PropTypes.func,
    prepareUpdate: PropTypes.func,
    type: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

export default connect(null, mapDispatchToProps)(CardListDetail);
