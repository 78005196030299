import { getItems } from 'common/api/companiesCatalog';

import {
    FETCH_COMPANIES,
    FETCH_COMPANIES_FAILURE,
    FETCH_COMPANIES_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_COMPANIES]());

    getItems()
        .then((response) => {
            const data = response.data.data;

            dispatch(actions[FETCH_COMPANIES_SUCCESS](data));
        })
        .catch((error) => {
            dispatch(actions[FETCH_COMPANIES_FAILURE](error));
        });
};
