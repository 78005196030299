import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

import LayoutRoute from 'common/components/LayoutRoute';
import { PERMISSION_LIST } from 'common/constants/permissions';
import { DefaultLayout, LoginLayout } from 'common/layouts';
import {
    getIsGuest,
    getSession,
    initSessionActions,
} from 'common/utils/session';

import applications from '../applications';
import company from '../company';
import configuration from '../configuration';
import creditFile from '../creditFile';
import editTemplateView from '../editTemplateView';
import financial from '../financial';
import funnel from '../funnel';
import installerProfile from '../installerProfile';
import installers from '../installers';
import login from '../login';
import notifications from '../notifications';
import profile from '../profile';
import proposalPreview from '../proposalPreview';
import quotePreview from '../quotePreview';
import templates from '../templates';

import PageNotFoundLayout from './components/PageNotFoundLayout';

const Container = ({ setCurrentValues }) => {
    const session = getSession();
    const isGuest = getIsGuest();
    if (!isGuest) {
        if (window.Intercom && process.env.REACT_APP_INTERCOM_APP_ID) {
            window.Intercom('boot', {
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                created_at: Math.round(new Date().getTime() / 1000),
                email: session.email,
                name: session.first_name + ' ' + session.last_name,
            });
        }
    }

    useEffect(() => {
        if (isGuest) return;
        setCurrentValues(session);
        initSessionActions();
    }, []);

    return (
        <Switch>
            <LayoutRoute
                component={login.Container}
                isPublic
                layout={LoginLayout}
                path="/login"
            />

            <LayoutRoute
                component={company.Container}
                exact
                layout={DefaultLayout}
                path={['/company', '/company/:id']}
                requiredPermissions={[
                    PERMISSION_LIST.GENERAL_INFORMATION_PERMISSION,
                    PERMISSION_LIST.ROLES_PERMISSION,
                    PERMISSION_LIST.USERS_PERMISSION,
                ]}
            />

            <LayoutRoute
                component={applications.Container}
                exact
                layout={DefaultLayout}
                path={['/', '/applications', '/applications/:id']}
                requiredPermissions={[
                    PERMISSION_LIST.ALLIANCE_PRODUCT_APPLICATIONS_PERMISSION,
                ]}
            />

            <LayoutRoute
                component={creditFile.Container}
                exact
                layout={DefaultLayout}
                path={['/credits/file', '/credits/file/:id']}
                requiredPermissions={[
                    PERMISSION_LIST.ALLIANCE_PRODUCT_APPLICATIONS_PERMISSION,
                ]}
            />

            <LayoutRoute
                component={installerProfile.Container}
                exact
                layout={DefaultLayout}
                path={['/installer-profile', '/installer-profile/:id']}
                requiredPermissions={[
                    PERMISSION_LIST.ALLIANCE_REQUESTS_PERMISSION,
                ]}
            />

            <LayoutRoute
                component={installers.Container}
                exact
                layout={DefaultLayout}
                path={['/installers', '/installers/:id']}
                requiredPermissions={[
                    PERMISSION_LIST.ALLIANCE_REQUESTS_PERMISSION,
                ]}
            />

            <LayoutRoute
                component={configuration.Container}
                exact
                layout={DefaultLayout}
                path={['/configuration', '/configuration/:id']}
                requiredPermissions={[
                    PERMISSION_LIST.INSURANCE_DOCUMENTS_PERMISSION,
                    PERMISSION_LIST.ALLIANCE_DOCUMENTS_PERMISSION,
                    PERMISSION_LIST.APPLICATION_DOCUMENTS_PERMISSION,
                ]}
            />

            <LayoutRoute
                component={profile.Container}
                exact
                layout={DefaultLayout}
                path={['/profile', '/profile/:id']}
            />
            <LayoutRoute
                component={financial.Container}
                exact
                layout={DefaultLayout}
                path="/financial"
                requiredPermissions={[
                    PERMISSION_LIST.ALLIANCE_PRODUCTS_PERMISSION,
                ]}
            />

            <LayoutRoute
                checkOnboarding
                checkPaymentDue
                component={funnel.Container}
                exact
                layout={DefaultLayout}
                multiBranchMode
                path={['/funnel', '/funnel/:id']}
                requiredPermissions={[
                    PERMISSION_LIST.ALLIANCE_PRODUCT_APPLICATIONS_PERMISSION,
                ]}
            />

            <LayoutRoute
                component={notifications.Container}
                exact
                layout={DefaultLayout}
                path={['/notifications', '/notifications/:id']}
            />

            <LayoutRoute
                component={templates.Container}
                exact
                layout={DefaultLayout}
                path={['/templates']}
            />

            <LayoutRoute
                component={editTemplateView.Container}
                exact
                layout={DefaultLayout}
                path="/templates/edition/:uid"
            />

            <LayoutRoute
                component={proposalPreview.Container}
                exact
                layout={DefaultLayout}
                path="/credits/:creditId/preview/:uid"
            />

            <LayoutRoute
                component={quotePreview.Container}
                exact
                layout={DefaultLayout}
                path="/preview/:uid"
            />

            <LayoutRoute
                component={<React.Fragment></React.Fragment>}
                layout={PageNotFoundLayout}
                path="*"
            />
        </Switch>
    );
};

Container.propTypes = { setCurrentValues: PropTypes.func };

const mapDispatchToProps = (dispatch) => ({
    setCurrentValues: (data) =>
        dispatch(profile.actions.setCurrentValues(data)),
});

export default connect(null, mapDispatchToProps)(Container);
