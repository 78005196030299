import { ContentTypeJSON } from 'common/utils/api';

import API from '..';

const ENTITY = 'financiers';

export const getCompany = () => API.get(`/api/v1/${ENTITY}/financier/me/`);
export const getFinancierExecutives = () =>
    API.get(`/api/v1/${ENTITY}/alliance-executives/`);
export const updateChatSettings = (data, id) =>
    API.patch(
        `/api/v1/${ENTITY}/financier/${id}/`,
        { ...data },
        ContentTypeJSON
    );
export const updateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
