import { filterQuotes } from 'common/api/quotes';

import {
    FETCH_QUOTES,
    FETCH_QUOTES_FAILURE,
    FETCH_QUOTES_SUCCESS,
} from '../actionTypes';
import { proposalsActions } from '../reducer';

export default ({
        company = '',
        orderBy = 'created_at',
        page = 0,
        pageSize = 25,
        rateName = 'all',
        searchText,
        sortBy = null,
    } = {}) =>
    (dispatch) => {
        dispatch(proposalsActions[FETCH_QUOTES]());

        filterQuotes({
            company,
            orderBy,
            page: page + 1,
            perPage: pageSize,
            rateName,
            searchText,
            sortBy,
        })
            .then((response) =>
                dispatch(proposalsActions[FETCH_QUOTES_SUCCESS](response.data))
            )
            .catch((error) =>
                dispatch(
                    proposalsActions[FETCH_QUOTES_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
