import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid } from 'sunwise-ui';

import { ChipProposalStatus, ProposalStatusIcon } from 'common/components';
import { getStatusProposalLabel } from 'common/utils/helpers';

import HeaderBreadcrumbs from './HeaderBreadcrumbs';
import TitlePreview from './TitlePreview';

const HeaderPreview = ({ creditId, fromQuote, hasApproved }) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Grid container spacing={0} sx={{ alignItems: 'center', p: 2 }}>
                <Grid item xs={13} md={14} lg={15}>
                    <TitlePreview name={t('Preview')} />
                </Grid>

                <Grid item md={4} lg={3} textAlign="right" xs={5}>
                    <ChipProposalStatus
                        icon={<ProposalStatusIcon hasApproved={hasApproved} />}
                        label={getStatusProposalLabel(hasApproved)}
                    />
                </Grid>

                <Grid item sx={{ pl: '40px' }} xs={18}>
                    <HeaderBreadcrumbs
                        creditId={creditId}
                        fromQuote={fromQuote}
                    />
                </Grid>
            </Grid>

            <Divider sx={{ mb: 0 }} />
        </Box>
    );
};

HeaderPreview.propTypes = {
    creditId: PropTypes.string,
    fromQuote: PropTypes.bool,
    hasApproved: PropTypes.bool,
};

export default HeaderPreview;
