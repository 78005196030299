import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Tab, TabPanel, Tabs, List } from 'sunwise-ui';

import {
    ALLIANCE_DOCUMENTS_CATEGORY,
    CONTRACT_TYPE,
    DOCUMENTS_TYPE,
    INSURANCE_DOCUMENTS_TYPE,
    INSURANCE_TYPE,
    PRIVATE_DOCUMENTS_TYPE,
    PRODUCT_DOCUMENTS_CATEGORY,
    REQUEST_FORM_TYPE,
} from 'common/constants';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ControlledListItem from './ControlledListItem';

const CardList = ({
    canViewAllianceDocs,
    canViewApplicationDocs,
    categoryType,
    fetchCatalogs,
    financierType,
    resetType,
    selectedTab,
    setSelectedTab,
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (canViewAllianceDocs) setSelectedTab('alliance');
        else if (canViewApplicationDocs) setSelectedTab('documents');
    }, [canViewAllianceDocs, canViewApplicationDocs]);

    const handleChange = (_, newValue) => {
        resetType();
        setSelectedTab(newValue);
    };

    const getIsSelected = (category, type) =>
        category === categoryType?.category && type === categoryType.type;

    const handleOnClickCatalogCard = (category, type) =>
        fetchCatalogs({ category, type });

    return (
        <React.Fragment>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    onChange={handleChange}
                    scrollButtons="auto"
                    value={selectedTab}
                    variant="scrollable"
                >
                    {canViewAllianceDocs && (
                        <Tab
                            id="tab-alliance"
                            key="tab-alliance"
                            label={t('Alliance')}
                            value="alliance"
                        />
                    )}
                    {canViewApplicationDocs && (
                        <Tab
                            id="tab-documents"
                            key="tab-documents"
                            label={t('Product request')}
                            value="documents"
                        />
                    )}
                </Tabs>
            </Box>

            {canViewAllianceDocs && (
                <TabPanel
                    key="tab-alliance"
                    selectedTab={selectedTab}
                    value="alliance"
                >
                    <List>
                        <ControlledListItem
                            category={ALLIANCE_DOCUMENTS_CATEGORY}
                            handleOnClickCatalogCard={handleOnClickCatalogCard}
                            getIsSelected={getIsSelected}
                            type={REQUEST_FORM_TYPE}
                            title={t('Request form')}
                        />

                        <ControlledListItem
                            category={ALLIANCE_DOCUMENTS_CATEGORY}
                            handleOnClickCatalogCard={handleOnClickCatalogCard}
                            getIsSelected={getIsSelected}
                            type={INSURANCE_DOCUMENTS_TYPE}
                            title={t('Insurance application form')}
                        />

                        <ControlledListItem
                            category={ALLIANCE_DOCUMENTS_CATEGORY}
                            handleOnClickCatalogCard={handleOnClickCatalogCard}
                            getIsSelected={getIsSelected}
                            type={DOCUMENTS_TYPE}
                            title={t('Document', { count: 2 })}
                        />

                        <ControlledListItem
                            category={ALLIANCE_DOCUMENTS_CATEGORY}
                            handleOnClickCatalogCard={handleOnClickCatalogCard}
                            getIsSelected={getIsSelected}
                            type={CONTRACT_TYPE}
                            title={t('Contract', { count: 2 })}
                        />

                        <ControlledListItem
                            category={ALLIANCE_DOCUMENTS_CATEGORY}
                            handleOnClickCatalogCard={handleOnClickCatalogCard}
                            getIsSelected={getIsSelected}
                            type={PRIVATE_DOCUMENTS_TYPE}
                            title={t('Private document', { count: 2 })}
                        />
                    </List>
                </TabPanel>
            )}

            {canViewApplicationDocs && (
                <TabPanel
                    key="tab-documents"
                    selectedTab={selectedTab}
                    value="documents"
                >
                    <List>
                        <ControlledListItem
                            category={PRODUCT_DOCUMENTS_CATEGORY}
                            handleOnClickCatalogCard={handleOnClickCatalogCard}
                            getIsSelected={getIsSelected}
                            type={REQUEST_FORM_TYPE}
                            title={t('Request form')}
                            visible={financierType !== INSURANCE_TYPE}
                        />

                        <ControlledListItem
                            category={PRODUCT_DOCUMENTS_CATEGORY}
                            handleOnClickCatalogCard={handleOnClickCatalogCard}
                            getIsSelected={getIsSelected}
                            type={INSURANCE_DOCUMENTS_TYPE}
                            title={t('Insurance application form')}
                            visible={financierType === INSURANCE_TYPE}
                        />

                        <ControlledListItem
                            category={PRODUCT_DOCUMENTS_CATEGORY}
                            handleOnClickCatalogCard={handleOnClickCatalogCard}
                            getIsSelected={getIsSelected}
                            type={DOCUMENTS_TYPE}
                            title={t('Document', { count: 2 })}
                        />

                        <ControlledListItem
                            category={PRODUCT_DOCUMENTS_CATEGORY}
                            handleOnClickCatalogCard={handleOnClickCatalogCard}
                            getIsSelected={getIsSelected}
                            type={CONTRACT_TYPE}
                            title={t('Contract', { count: 2 })}
                        />

                        <ControlledListItem
                            category={PRODUCT_DOCUMENTS_CATEGORY}
                            handleOnClickCatalogCard={handleOnClickCatalogCard}
                            getIsSelected={getIsSelected}
                            type={PRIVATE_DOCUMENTS_TYPE}
                            title={t('Private document', { count: 2 })}
                        />
                    </List>
                </TabPanel>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    categoryType: selectors.getType,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCatalogs: (type) => dispatch(actions.fetchCatalogs(type)),
});

CardList.propTypes = {
    canViewAllianceDocs: PropTypes.bool,
    canViewApplicationDocs: PropTypes.bool,
    categoryType: PropTypes.object,
    fetchCatalogs: PropTypes.func,
    financierType: PropTypes.number,
    resetType: PropTypes.func,
    selectedTab: PropTypes.string,
    setSelectedTab: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardList);
