import React, { useEffect } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, IconButton, Tooltip, Typography } from 'sunwise-ui';

import * as actions from './actions';
import TableSettingsModal from './components/TableSettingsModal';
import * as selectors from './selectors';

const Container = ({
    columnKeys,
    fetchTableSettings,
    handleClickOpenModal,
    isOpenModal,
    requiredColumnsKeys,
    tableKey,
    tableSettings,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (tableKey)
            fetchTableSettings(tableKey, columnKeys, requiredColumnsKeys);
    }, [tableKey]);

    return (
        <Box>
            <Box
                alignItems="center"
                display="flex"
                gap={1}
                justifyContent="end"
                p={1}
            >
                <Typography variant="body2" fontWeight="bold">
                    {t('Column', { count: 2 })}
                </Typography>

                <Tooltip placement="top" title="Configurar">
                    <IconButton onClick={() => handleClickOpenModal(true)}>
                        <SettingsIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Box>

            <TableSettingsModal
                columnKeys={columnKeys}
                fetchTableSettings={fetchTableSettings}
                handleClickCloseModal={() => handleClickOpenModal(false)}
                isOpenModal={isOpenModal}
                requiredColumnsKeys={requiredColumnsKeys}
                tableKey={tableKey}
                tableSettings={tableSettings}
            />
        </Box>
    );
};

Container.propTypes = {
    columnKeys: PropTypes.object,
    fetchTableSettings: PropTypes.func,
    handleClickOpenModal: PropTypes.func,
    isOpenModal: PropTypes.bool,
    requiredColumnsKeys: PropTypes.array,
    tableKey: PropTypes.string,
    tableSettings: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isOpenModal: selectors.getIsOpenModal,
    tableSettings: selectors.getTableSettingsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTableSettings: (tableKey, columnKeys, requiredColumnsKeys) =>
        dispatch(
            actions.fetchTableSettings(
                tableKey,
                columnKeys,
                requiredColumnsKeys
            )
        ),
    handleClickOpenModal: (value) => dispatch(actions.setIsOpenModal(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
