import { NAME } from './constants';

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const FETCH_QUOTES = `${NAME}/FETCH_QUOTES`;
export const FETCH_QUOTES_FAILURE = `${NAME}/FETCH_QUOTES_FAILURE`;
export const FETCH_QUOTES_SUCCESS = `${NAME}/FETCH_QUOTES_SUCCESS`;

export const FETCH_RATES = `${NAME}/FETCH_RATES`;
export const FETCH_RATES_FAILURE = `${NAME}/FETCH_RATES_FAILURE`;
export const FETCH_RATES_SUCCESS = `${NAME}/FETCH_RATES_SUCCESS`;

export const SAVE_BULK_TRACKINGS = `${NAME}/SAVE_BULK_TRACKINGS`;
export const SAVE_BULK_TRACKINGS_FAILURE = `${NAME}/SAVE_BULK_TRACKINGS_FAILURE`;
export const SAVE_BULK_TRACKINGS_SUCCESS = `${NAME}/SAVE_BULK_TRACKINGS_SUCCESS`;

export const UPDATE_ITEMS_SUCCESS = `${NAME}/UPDATE_ITEMS_SUCCESS`;
