import React, { useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Divider, Grid, Switch, Typography } from 'sunwise-ui';

import * as companySelectors from '../../companyGeneral/selectors';

const ChatAlliance = ({
    chatsConfig,
    fetchCompany,
    financierId,
    handleChangeSwitch,
}) => {
    const { t } = useTranslation();
    const config = get(chatsConfig, 'chat_settings', {});
    const [checked, setChecked] = useState(config?.chat_alliance);

    const callback = () => fetchCompany();
    const handleChange = (event) => {
        setChecked(event.target.checked);
        handleChangeSwitch(
            {
                chat_alliance: event.target.checked,
            },
            financierId,
            callback
        );
    };

    return (
        <Box pb={2} px={2}>
            <Typography fontWeight="bold" variant="body2">
                Chats
            </Typography>
            <Divider sx={{ mb: '8px' }} />
            <Grid alignItems="center" container>
                <Grid item xs>
                    <Typography variant="body1">{t('Chat on file')}</Typography>
                </Grid>

                <Grid item>
                    <Switch
                        checked={checked}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

ChatAlliance.propTypes = {
    chatsConfig: PropTypes.object,
    fetchCompany: PropTypes.func,
    financierId: PropTypes.string,
    handleChangeSwitch: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    chatsConfig: companySelectors.getChatsConfig,
    financierId: companySelectors.getFinancierId,
});

export default connect(mapStateToProps, null)(ChatAlliance);
