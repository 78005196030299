import { ContentTypeJSON } from 'common/utils/api';

import API from '..';
const ENTITY = 'templates';

export const addTemplatePages = (id, data) =>
    API.patch(`/api/v1/${ENTITY}/pages/${id}/`, { ...data }, ContentTypeJSON);
export const bulkTemplateStatus = (data) =>
    API.put(
        `/api/v1/${ENTITY}/bulk-change-status/`,
        { ...data },
        ContentTypeJSON
    );
export const createTemplate = (data) =>
    API.post(`/api/v1/${ENTITY}/listing/`, { ...data });
export const duplicateTemplate = (id, values) =>
    API.post(`/api/v1/${ENTITY}/template/${id}/duplicate/`, values);
export const filterTemplates = ({
    orderBy,
    page = 1,
    perPage = 25,
    searchText,
    sortBy,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/listing/`, {
        params: {
            page: page,
            per_page: perPage,
            status_flag: status,
            ...(orderBy ? { order_by: orderBy } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(sortBy ? { sort: sortBy } : {}),
        },
    });
export const getOfferCustomTemplate = (id) =>
    API.get(`/api/v1/${ENTITY}/content/${id}/`);
export const getTemplatesCatalog = () =>
    API.get(`/api/v1/${ENTITY}/catalog/`, {
        params: {
            language: 'es-mx',
        },
    });
export const updateTemplate = (data, id) =>
    API.put(`/api/v1/${ENTITY}/content/${id}/`, { ...data });
export const updateTemplateByRowEdition = (id, data) =>
    API.patch(
        `/api/v1/${ENTITY}/template/${id}/`,
        { ...data },
        ContentTypeJSON
    );
