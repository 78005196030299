import { initChat as initChatAlliance } from 'common/api/chatAlliance';
import { initChat as initChatCredit } from 'common/api/chatCredit';

import {
    INIT_CHAT,
    INIT_CHAT_FAILURE,
    INIT_CHAT_SUCCESS,
} from '../actionTypes';
import { ALLIANCE_DOCUMENT_TYPE } from '../constants';
import { chatActions } from '../reducer';

import fetchMessages from './fetchMessages';

export default (canSendMessage, itemId, type, user) => (dispatch) => {
    const action =
        type === ALLIANCE_DOCUMENT_TYPE ? initChatAlliance : initChatCredit;

    dispatch(chatActions[INIT_CHAT]());

    action(itemId)
        .then((response) =>
            dispatch(chatActions[INIT_CHAT_SUCCESS](response.data))
        )
        .catch((error) =>
            dispatch(
                chatActions[INIT_CHAT_FAILURE](error?.response?.data?.errors)
            )
        )
        .finally(() =>
            dispatch(fetchMessages(canSendMessage, itemId, type, user))
        );
};
