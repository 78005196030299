import get from 'lodash/get';

import { fetchRequirements } from 'common/api/financierAllianceInstaller';

import {
    FETCH_TERMS_AND_CONDITIONS,
    FETCH_TERMS_AND_CONDITIONS_FAILURE,
    FETCH_TERMS_AND_CONDITIONS_SUCCESS,
} from '../actionTypes';
import { companyGeneralActions } from '../reducer';

import initializeTermsForm from './initializeTermsForm';

export default () => (dispatch) => {
    dispatch(companyGeneralActions[FETCH_TERMS_AND_CONDITIONS]());

    fetchRequirements()
        .then((response) => {
            const data = get(response, 'data.data', {});
            const content = get(data[0], 'content', '');
            dispatch(
                companyGeneralActions[FETCH_TERMS_AND_CONDITIONS_SUCCESS](data)
            );
            dispatch(initializeTermsForm({ content }));
        })
        .catch((error) =>
            dispatch(
                companyGeneralActions[FETCH_TERMS_AND_CONDITIONS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
