import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

const getMaxItems = ({ breakpoint } = {}) => {
    if (breakpoint === 'xs') return 2;
    if (breakpoint === 'sm') return 3;
    return 4;
};

const HeaderBreadcrumbs = ({ creditId, fromQuote }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const url = fromQuote
        ? '/applications/quotes'
        : `/credits/file/${creditId}`;

    return (
        <Breadcrumbs maxItems={getMaxItems({ breakpoint })}>
            <Link
                color="secondary"
                component={RouterLink}
                sx={{ fontSize: '14px', '&:hover': { color: 'primary.main' } }}
                to={url}
                underline="hover"
            >
                <Typography variant="body2">
                    {fromQuote ? t('Quote', { count: 2 }) : t('Credit file')}
                </Typography>
            </Link>

            <Typography sx={{ color: 'primary.main', fontSize: '14px' }}>
                {t('Preview')}
            </Typography>
        </Breadcrumbs>
    );
};

HeaderBreadcrumbs.propTypes = {
    creditId: PropTypes.string,
    fromQuote: PropTypes.bool,
};

export default HeaderBreadcrumbs;
