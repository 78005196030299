import i18next from 'i18next';

export const getRateOptions = (rates) => {
    const items = rates.map((rate) => ({
        label: rate.name,
        value: rate.name,
    }));

    return [
        {
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: 'all',
        },
        ...items,
    ];
};

export const getCompanyOptions = (companies) => {
    return companies.map((item) => ({
        label: item.name,
        value: item.id,
    }));
};
