import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from 'sunwise-ui';

import { arraytoDictionary } from 'common/utils/helpers';

import { getLanguageOptions } from '../helpers';

import ActionsFormatter from './ActionsFormatter';

const ProductColumns = ({
    canDelete,
    canUpdate,
    filterData,
    handleClickBulkItems,
    handleClickDuplicate,
    handleClickRename,
    handleClickUpdate,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();

    return [
        {
            align: 'left',
            editable: true,
            field: 'title',
            flex: 1,
            headerName: t('Title'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Link
                        color="secondary"
                        component={RouterLink}
                        to={`/templates/edition/${row.id}`}
                    >
                        <Typography fontWeight="bold" variant="body2">
                            {row.title}
                        </Typography>
                    </Link>
                );
            },
        },
        {
            align: 'left',
            field: 'language',
            headerName: t('Language'),
            renderCell: (params) => {
                const { row } = params;
                const languagesDictionary = arraytoDictionary(
                    getLanguageOptions(),
                    'value'
                );
                return (
                    <Typography variant="caption">
                        {languagesDictionary[row.language]?.label}
                    </Typography>
                );
            },
            width: 180,
        },
        {
            align: 'left',
            field: 'created_at',
            headerName: t('Creation date'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">{row.created_at}</Typography>
                );
            },
            width: 180,
        },
        {
            align: 'left',
            field: '',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ActionsFormatter
                        canDelete={canDelete}
                        canUpdate={canUpdate}
                        filterData={filterData}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickDuplicate={handleClickDuplicate}
                        handleClickRename={handleClickRename}
                        handleClickUpdate={handleClickUpdate}
                        row={row}
                        rowModesModel={rowModesModel}
                        selectedRowId={selectedRowId}
                        setRowModesModel={setRowModesModel}
                        setSelectedRowId={setSelectedRowId}
                    />
                );
            },
            sortable: false,
            width: 180,
        },
    ];
};

ProductColumns.propTypes = {
    canDelete: PropTypes.bool,
    canUpdate: PropTypes.bool,
    handleClickBulkItems: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickRename: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductColumns;
