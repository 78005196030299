export const NAME = 'credits';
export const COLUMN_KEYS = {
    client_name: 'Client name',
    business_name: 'Business name',
    amount: 'Amount',
    type_business: 'Type business',
    project_name: 'Project',
    financial_advisor: 'Assigned executive',
    finacier_product_name: 'Financial product',
    rate: 'Rate',
    term: 'Term',
    status: 'Status',
    requested_at: 'Creation date',
    updated_at: 'Updated at',
    created_at: 'Created at',
};
export const REQUIRED_COLUMNS_KEYS = ['client_name', 'updated_at'];
