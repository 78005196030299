import React from 'react';

import PropTypes from 'prop-types';
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

const ReduxFieldSelect = (props) => {
    const {
        disabled,
        id,
        input: { value, onChange },
        label,
        meta: { touched, error },
        options,
        placeholder,
        required,
        sx,
        sxBox,
        variant,
    } = props;

    return (
        <Box sx={{ ...sxBox, width: '100%' }}>
            <FormControl
                fullWidth
                error={Boolean(error) && touched}
                size="small"
                sx={
                    variant === 'standard'
                        ? {
                              '& .MuiFormLabel-root': {
                                  transform: 'translate(0px, 14px) scale(1)',
                              },
                              '& .MuiFormLabel-root.MuiFormLabel-filled': {
                                  transform: 'translate(0px, -9px) scale(0.75)',
                              },
                              '& .MuiFormLabel-root.Mui-focused': {
                                  transform: 'translate(0px, -9px) scale(0.75)',
                              },
                          }
                        : {}
                }
            >
                {label && <InputLabel>{label}</InputLabel>}

                <Select
                    disabled={disabled}
                    id={id}
                    label={label}
                    onChange={onChange}
                    placeholder={placeholder}
                    required={required}
                    sx={sx}
                    value={value}
                    variant={variant}
                >
                    {options.map(
                        ({ label, value, disabled: optionDisabled }) => (
                            <MenuItem
                                value={value}
                                disabled={optionDisabled}
                                key={`select-${value}-${label}`}
                                sx={{ fontSize: '14px' }}
                            >
                                {label}
                            </MenuItem>
                        )
                    )}
                </Select>

                {Boolean(error) && touched && (
                    <FormHelperText>{error}</FormHelperText>
                )}
            </FormControl>
        </Box>
    );
};

ReduxFieldSelect.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    sx: PropTypes.object,
    sxBox: PropTypes.object,
    variant: PropTypes.string,
};

export default ReduxFieldSelect;
