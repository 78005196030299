import { NAME } from './constants';

export const FETCH_COMPANY = `${NAME}/FETCH_COMPANY`;
export const FETCH_COMPANY_FAILURE = `${NAME}/FETCH_COMPANY_FAILURE`;
export const FETCH_COMPANY_SUCCESS = `${NAME}/FETCH_COMPANY_SUCCESS`;

export const FETCH_TERMS_AND_CONDITIONS = `${NAME}/FETCH_TERMS_AND_CONDITIONS`;
export const FETCH_TERMS_AND_CONDITIONS_FAILURE = `${NAME}/FETCH_TERMS_AND_CONDITIONS_FAILURE`;
export const FETCH_TERMS_AND_CONDITIONS_SUCCESS = `${NAME}/FETCH_TERMS_AND_CONDITIONS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const INITIALIZE_FORM_TERMS = `${NAME}/INITIALIZE_FORM_TERMS`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_TERMS_FORM = `${NAME}/RESET_TERMS_FORM`;

export const SAVE_COMPANY = `${NAME}/SAVE_COMPANY`;
export const SAVE_COMPANY_FAILURE = `${NAME}/SAVE_COMPANY_FAILURE`;
export const SAVE_COMPANY_SUCCESS = `${NAME}/SAVE_COMPANY_SUCCESS`;

export const SAVE_TERMS_AND_CONDITIONS = `${NAME}/SAVE_TERMS_AND_CONDITIONS`;
export const SAVE_TERMS_AND_CONDITIONS_FAILURE = `${NAME}/SAVE_TERMS_AND_CONDITIONS_FAILURE`;
export const SAVE_TERMS_AND_CONDITIONS_SUCCESS = `${NAME}/SAVE_TERMS_AND_CONDITIONS_SUCCESS`;
