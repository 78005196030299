import {
    saveRequirements,
    updateRequirements,
} from 'common/api/financierAllianceInstaller';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_TERMS_AND_CONDITIONS,
    SAVE_TERMS_AND_CONDITIONS_FAILURE,
    SAVE_TERMS_AND_CONDITIONS_SUCCESS,
} from '../actionTypes';
import { companyGeneralActions } from '../reducer';

import fetchTermsAndConditions from './fetchTermsAndConditions';
import resetTermsForm from './resetTermsForm';

export default (values) => (dispatch) => {
    const newValues = {
        content: values.content,
        required: true,
    };

    let action = values.id
        ? updateRequirements({ ...newValues, id: values.id })
        : saveRequirements(newValues);

    dispatch(companyGeneralActions[SAVE_TERMS_AND_CONDITIONS]());

    action
        .then(() => {
            dispatch(
                companyGeneralActions[SAVE_TERMS_AND_CONDITIONS_SUCCESS]()
            );

            dispatch(fetchTermsAndConditions());
            dispatch(resetTermsForm());

            showToast();
        })
        .catch((error) => {
            dispatch(
                companyGeneralActions[SAVE_TERMS_AND_CONDITIONS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
