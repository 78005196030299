import React from 'react';

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from 'sunwise-ui';

const NameFormatter = ({ row }) => {
    const { proposal_id, proposal_name } = row;
    return (
        <Link
            color="inherit"
            component={RouterLink}
            to={`/preview/${proposal_id}`}
            underline="none"
        >
            <Typography fontWeight={600} variant="caption">
                {proposal_name}
            </Typography>
        </Link>
    );
};

NameFormatter.propTypes = {
    row: PropTypes.object,
};

export default NameFormatter;
