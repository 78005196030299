import Bugsnag from '@bugsnag/js';
import i18n from 'i18next';
import { cloneDeep, get, isEmpty, isNull, keyBy, merge, values } from 'lodash';

import { CI_BANCO_UID, SERFIMEX_UID } from 'common/constants';

import { arraytoDictionary, hasValue } from './helpers';

export const getToken = () => localStorage.getItem('token');

export const getIsGuest = () => (getToken() ? false : true);

export const getSessionLocalStorage = () =>
    typeof localStorage.getItem('session') !== 'undefined'
        ? JSON.parse(localStorage.getItem('session'))
        : {};

export const getSession = () => {
    if (localStorage.getItem('session'))
        return JSON.parse(localStorage.getItem('session'));
    return {};
};

export const getChatsConfig = () => {
    const session = getSession();
    return get(session, 'chat_settings', {});
};

export const getGroups = () => {
    if (localStorage.getItem('groups')) {
        const groups = JSON.parse(localStorage.getItem('groups'));
        return groups.filter((group) => group.is_enabled === true);
    }
    return [];
};

export const getIsValidLogin = () => {
    const groups = getGroups();
    const session = getSession();
    return getToken() && !isEmpty(groups) && !isEmpty(session);
};

export const getPermissionsDictionary = () =>
    arraytoDictionary(getGroups(), 'group_id');

export const getUserPermits = () => {
    const groups = getGroups();
    if (!isEmpty(groups)) {
        const groupsFiltered = groups.filter(
            (item) => item.is_enabled && item.permission_level !== 'none'
        );
        return groupsFiltered.map((group) => group.group_id);
    }
    return [];
};

export const getFinancierGroupType = () => {
    const session = getSession();
    return session.financier_group.financier_group_type;
};

export const getIsCiBanco = () => {
    const session = getSession();
    return session.financier === CI_BANCO_UID;
};

export const getIsSerfimex = () => {
    const session = getSession();
    return session.financier === SERFIMEX_UID;
};

export const getFinancierId = () => {
    const session = getSession();
    return session?.financier;
};

export const getFinancierType = () => {
    const session = getSession();
    return session.type;
};

export const getUserSettings = () => {
    const session = getSession();
    return session.settings;
};

export const logoutActions = () => {
    localStorage.clear();
    window.location.replace('/login');
};

export const updateSession = (props) => {
    const session = getSession();
    if (session && props) {
        localStorage.setItem(
            'session',
            JSON.stringify(Object.assign(session, props))
        );
    }
};

export const updatePermissionGroups = (updatedGroups) => {
    const groups = getGroups();
    const clone = cloneDeep(groups);

    if (clone && updatedGroups.length) {
        const merged = merge(
            keyBy(clone, 'group_id'),
            keyBy(updatedGroups, 'group_id')
        );
        const newGroups = values(merged);
        localStorage.setItem('groups', JSON.stringify(newGroups));
    }
};

export const initSessionActions = (callback) => {
    const session = getSession();
    if (session) {
        if (callback) callback();

        Bugsnag.addMetadata('user', {
            first_name: session.first_name,
            last_name: session.last_name,
            email: session.email,
        });

        if (session?.settings?.language?.key)
            i18n.changeLanguage(session.settings.language.key);
    } else {
        Bugsnag.clearMetadata('user');
    }
};

export const getCountryCurrency = () => {
    const session = getSessionLocalStorage();
    return get(session, 'currency_company_locale', {});
};

export const getCountryCurrencyIso = () => {
    const currency = getCountryCurrency();
    if (!hasValue(currency, 'abbreviation')) return 'USD';
    return get(currency, 'abbreviation', 'USD');
};

export const getCountryCurrencyLocale = () => {
    const currency = getCountryCurrency();
    if (!isEmpty(currency) && !isNull(currency) && hasValue(currency, 'locale'))
        return get(currency, 'locale', 'en-US').replace(/_/g, '-');
    return 'en-US';
};
