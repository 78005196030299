import { createSlice } from '@reduxjs/toolkit';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    FETCH_QUOTES,
    FETCH_QUOTES_FAILURE,
    FETCH_QUOTES_SUCCESS,
    FETCH_RATES,
    FETCH_RATES_FAILURE,
    FETCH_RATES_SUCCESS,
    SAVE_BULK_TRACKINGS,
    SAVE_BULK_TRACKINGS_FAILURE,
    SAVE_BULK_TRACKINGS_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    bulkUpdateItems: {
        errors: [],
        isSaving: false,
    },
    fetchQuotes: {
        data: {
            data: [],
            pagination: null,
        },
        errors: [],
        isFetching: false,
    },
    fetchRates: { data: [], errors: [], isFetching: false },
};

const proposalsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: [],
                isSaving: true,
            };
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: action.payload,
                isSaving: false,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                isSaving: false,
            };
        },
        [FETCH_QUOTES]: (state) => {
            state.fetchQuotes.isFetching = true;
        },
        [FETCH_QUOTES_FAILURE]: (state, action) => {
            state.fetchQuotes = {
                ...state.fetchQuotes,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_QUOTES_SUCCESS]: (state, action) => {
            state.fetchQuotes = {
                ...state.fetchQuotes,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_RATES]: (state) => {
            state.fetchRates = {
                ...state.fetchRates,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_RATES_FAILURE]: (state, action) => {
            state.fetchRates = {
                ...state.fetchRates,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_RATES_SUCCESS]: (state, action) => {
            state.fetchRates = {
                ...state.fetchRates,
                data: action.payload,
                errors: [],
                isFetching: false,
            };
        },
        [SAVE_BULK_TRACKINGS]: (state) => {
            state.saveTracking = {
                ...state.saveTracking,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_BULK_TRACKINGS_FAILURE]: (state, action) => {
            state.saveTracking = {
                ...state.saveTracking,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_BULK_TRACKINGS_SUCCESS]: (state, action) => {
            state.saveTracking = {
                ...state.saveTracking,
                data: action.payload,
                isSaving: false,
            };
        },
        [UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.fetchQuotes.data.data = action.payload;
        },
    },
});

export const proposalsActions = proposalsSlice.actions;

export default proposalsSlice.reducer;
